import { Export, HappyDrop, Parcelles, Smag, User } from "@hygo/shared/icons";
import { DenormalizedIrrigation, DenormalizedTask, TasksEvents } from "@hygo/shared/models";
import { COLORS, formatDateToLocale } from "@hygo/shared/utils";
import {
	Button,
	EmptyState,
	FieldsListCard,
	IrrigationCard,
	Manager,
	SimpleButton,
	TaskCard
} from "@hygo/web/ui-components";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import SeasonTabs from "./components/SeasonTabs";
import Skeleton from "./components/Skeleton";
import { TasksScreenProps } from "./screen.types";

const Wrapper = styled.div`
	height: 100%;
	display: flex;
	background: var(--gradient-background-2);
`;

const ActionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;

const TasksList = styled.div`
	height: 100%;
	overflow: auto;
`;

const DateTitle = styled.h4`
	margin-bottom: 16px;
`;

const ActionButton = styled(Button)`
	min-width: 40px;
`;

const EmptyStatesWrapper = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const TasksWrapper = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	flex: 2;
	overflow: hidden;
	gap: 28px;
	padding: 16px;
`;

const TasksScreen = ({
	adminWithoutUserSelected,
	crops,
	csvExporting,
	defaultFarm,
	exportMode,
	farms,
	fields,
	filteredList,
	goToDashboard,
	handleSelectField,
	loading,
	loggedInSmag,
	onCancelExport,
	onChangeSeason,
	onClickExport,
	onDeleteIrrigation,
	onDeleteTask,
	onOpenTaskDetails,
	onSelectAll,
	onUnselectAll,
	resetSelection,
	selectedFields,
	selectedSeason,
	selectedTaskIds,
	smagExportableTaskIds,
	smagExporting,
	stepSeasons,
	updateDefaultFarm,
	updateSelectedTaskIds,
	user
}: TasksScreenProps): JSX.Element => {
	const { i18n, t } = useTranslation();

	return (
		<Wrapper>
			<Manager
				defaultFarm={defaultFarm}
				farms={farms}
				updateDefaultFarm={updateDefaultFarm}
				withCrudActions={false}
			>
				<FieldsListCard
					crops={crops}
					farms={farms}
					fields={fields}
					handleFieldSelection={({ field }) => handleSelectField(field)}
					loading={loading}
					multiSelectionEnabled={false}
					resetSelection={resetSelection}
					selectedFields={selectedFields}
					selectFieldEvent={TasksEvents.filterTasksWithField}
					showEmptyStates={false}
					withFooter={false}
					withNeedCheck={false}
				/>
			</Manager>

			<TasksWrapper>
				{farms?.length > 0 && !adminWithoutUserSelected && (
					<ActionsWrapper>
						<SeasonTabs
							onChangeSeason={onChangeSeason}
							selectedSeason={selectedSeason}
							stepSeasons={stepSeasons}
						/>

						{loggedInSmag && exportMode !== "GEOFOLIA" && (
							<ActionButton
								color={COLORS.TANGERINE}
								disabled={
									exportMode === "SMAG"
										? selectedTaskIds?.length === 0
										: smagExportableTaskIds?.length === 0
								}
								fillIcon
								Icon={Smag}
								loading={smagExporting}
								onClick={() => onClickExport("SMAG")}
								text={
									exportMode === "SMAG"
										? t("screens.tasks.confirmExportSmagBtn", {
												tasksNumber: selectedTaskIds.length
											})
										: t("screens.tasks.exportSmagBtn", {
												tasksNumber: smagExportableTaskIds.length
											})
								}
								tiny
								width="fit-content"
							/>
						)}

						{exportMode !== "SMAG" && (
							<ActionButton
								color={COLORS.LAKE}
								disabled={
									exportMode === "GEOFOLIA"
										? selectedTaskIds?.length === 0
										: csvExporting || filteredList?.list?.length === 0
								}
								fillIcon
								Icon={Export}
								onClick={() => onClickExport("GEOFOLIA")}
								text={
									exportMode === "GEOFOLIA"
										? t("screens.tasks.confirmExportSmagBtn", {
												tasksNumber: selectedTaskIds.length
											})
										: t("screens.tasks.exportBtn")
								}
								tiny
								width="fit-content"
							/>
						)}
						{!!exportMode && (
							<Button
								color={COLORS.SMOKE}
								inverted
								onClick={onCancelExport}
								text={t("button.cancel")}
								tiny
								width="fit-content"
							/>
						)}
					</ActionsWrapper>
				)}
				{!filteredList?.list?.length || loading || adminWithoutUserSelected ? (
					<>
						{loading && <Skeleton />}
						{!loading && (
							<EmptyStatesWrapper>
								{!farms?.length && !adminWithoutUserSelected && (
									<EmptyState
										btnIcon={Parcelles}
										btnText={t("components.emptyState.noDefaultFarm.btn")}
										description={t("components.emptyState.noDefaultFarm.description")}
										illustration={<img alt="ferme" src={"/assets/images/coop.png"} width={100} />}
										onClick={goToDashboard}
										title={t("components.emptyState.noDefaultFarm.title")}
									/>
								)}
								{!adminWithoutUserSelected && farms?.length > 0 && (
									<EmptyState
										description={t("screens.tasks.emptyStates.noTasks.description")}
										grey
										illustration={
											<HappyDrop
												firstColor={COLORS.NIGHT[25]}
												height={48}
												secondColor={COLORS.NIGHT[25]}
												width={33}
											/>
										}
										title={t("screens.tasks.emptyStates.noTasks.title")}
									/>
								)}

								{adminWithoutUserSelected && (
									<EmptyState
										description={t("screens.tasks.emptyStates.noUserSelected.description")}
										grey
										illustration={<User fill={COLORS.NIGHT[25]} height={48} width={48} />}
										title={t("screens.tasks.emptyStates.noUserSelected.title")}
									/>
								)}
							</EmptyStatesWrapper>
						)}
					</>
				) : (
					<>
						{!!exportMode &&
							(selectedTaskIds?.length > 0 ? (
								<SimpleButton
									onClick={onUnselectAll}
									text={t("screens.tasks.unselectAllBtn", {
										numberOfSelectedTasks: selectedTaskIds.length
									})}
								/>
							) : (
								<SimpleButton onClick={onSelectAll} text={t("screens.tasks.selectAllBtn")} />
							))}

						<TasksList>
							{filteredList?.list?.map((taskGroup): JSX.Element => {
								return (
									<Fragment key={taskGroup.title}>
										<DateTitle>
											{formatDateToLocale(
												new Date(taskGroup.title),
												`${i18n.resolvedLanguage}-${user?.countryCode}`
											)}
										</DateTitle>
										{taskGroup.data.map((item) => {
											if ((item as DenormalizedTask)?.products?.length > 0) {
												const task = item as DenormalizedTask;
												return (
													<TaskCard
														crops={crops}
														defaultFarm={defaultFarm}
														exportMode={!!exportMode}
														key={`task-${task.id}`}
														onClickCheckbox={updateSelectedTaskIds}
														onDeleteTask={onDeleteTask}
														onOpenTaskDetails={onOpenTaskDetails}
														selectedFields={selectedFields}
														selectedTaskIds={selectedTaskIds}
														task={task}
														user={user}
													/>
												);
											} else {
												const irrigation = item as DenormalizedIrrigation;
												return (
													<IrrigationCard
														crops={crops}
														irrigation={irrigation}
														key={`irrigation-${irrigation.id}`}
														onDeleteIrrigation={onDeleteIrrigation}
														selectedFields={selectedFields}
														user={user}
													/>
												);
											}
										})}
									</Fragment>
								);
							})}
						</TasksList>
					</>
				)}
			</TasksWrapper>
		</Wrapper>
	);
};

export default TasksScreen;
