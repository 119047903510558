import { Crop } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Checkbox from "../../Checkbox";
import CropIcon from "../../CropIcon";

interface AccordionSummaryProps {
	area: number;
	checked: boolean;
	crop: Crop;
	isIndeterminate: boolean;
	needCheck: boolean;
	numberOfFields: number;
	onClickCheckbox?: (e: MouseEvent<HTMLDivElement>, crop: string) => void;
	withCheckbox: boolean;
}

const Wrapper = styled.div<{ $needCheck: boolean }>`
	display: flex;
	align-items: center;
	gap: 8px;
	overflow: hidden;
	color: var(${(props) => (props.$needCheck ? "--gaspacho-100" : "--night-100")});
`;

const StyledCheckbox = styled(Checkbox)``;

const NumberOfFields = styled.h4<{ $needCheck: boolean }>`
	color: var(${(props) => (props.$needCheck ? "--gaspacho-100" : "--night-50")});
`;

const Name = styled.h4`
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const Area = styled(Name)<{ $needCheck: boolean }>`
	margin-right: 0;
	color: var(${(props) => (props.$needCheck ? "--gaspacho-100" : "--night-50")});
`;

const AccordionSummary = ({
	area,
	checked,
	crop,
	isIndeterminate,
	needCheck,
	numberOfFields,
	onClickCheckbox,
	withCheckbox
}: AccordionSummaryProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<Wrapper $needCheck={needCheck}>
			{withCheckbox && (
				<StyledCheckbox
					checked={checked}
					isIndeterminate={isIndeterminate}
					onClick={(e) => onClickCheckbox(e, crop?.name)}
				/>
			)}
			<CropIcon crop={crop} fill={needCheck ? COLORS.GASPACHO[100] : COLORS.NIGHT[100]} />
			<Name>{crop.name}</Name>
			<NumberOfFields $needCheck={needCheck}>({numberOfFields})</NumberOfFields>
			<Area $needCheck={needCheck}>
				{area} {t("units.hectare")}
			</Area>
		</Wrapper>
	);
};

export default AccordionSummary;
