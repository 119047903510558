import { Clock } from "@hygo/shared/icons";
import { Crop, DenormalizedField, Tank, WindSpeedUnity } from "@hygo/shared/models";
import { COLORS, computeColorFromConditions, convertToHa, transformDate } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import ParcelSVG from "../../ParcelSVG";
import MetricsProductList from "./MetricsProductList";
import WeatherDetails from "./WeatherDetails";

interface FieldCardProps {
	crops: Crop[];
	field: DenormalizedField;
	isSelected: boolean;
	tankIndications: Tank;
	windSpeedUnity: WindSpeedUnity;
}

const Wrapper = styled.div<{ $isSelectedField: boolean }>`
	width: 420px;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	border-radius: 4px;
	opacity: ${(props) => (props.$isSelectedField ? 1 : 0.25)};
	display: inline-block;
	margin-right: 16px;
	&:last-child {
		margin-right: 0;
	}
`;

const DateWrapper = styled.div`
	padding: 8px 16px;
	border-radius: 4px;
	background: var(--gradient-light-grey);
	display: flex;
	align-items: center;
`;

const DateText = styled.h5`
	margin-left: 8px;
	color: var(--night-100);
`;

const Content = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 16px;
`;

const NameCropWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CropItem = styled.div`
	padding: 8px 16px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	background-color: var(--night-5);
`;

const NameWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const Name = styled.h4`
	color: var(--night-100);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 120px;
`;

const Area = styled(Name)`
	color: var(--night-50);
`;

const NoWeatherDetails = styled.h5`
	background-color: var(--night-5);
	padding: 8px 16px;
	border-radius: 4px;
	white-space: initial;
`;

const FieldCard = ({ crops, field, isSelected, tankIndications, windSpeedUnity }: FieldCardProps): JSX.Element => {
	const { t } = useTranslation();
	const crop = crops.find((c) => c.id === field?.cropId);

	return (
		<Wrapper $isSelectedField={isSelected}>
			{!!field?.startTime && !!field?.endTime && (
				<DateWrapper>
					<Clock fill={COLORS.NIGHT[25]} height={16} width={16} />
					<DateText>
						{field?.detected ? (
							<>
								{transformDate(new Date(field.startTime), 5, Math.round)} -
								{transformDate(new Date(field.endTime), 5, Math.round)}
							</>
						) : (
							t("components.fieldsReportCard.noDetection")
						)}
					</DateText>
				</DateWrapper>
			)}
			<Content>
				<NameCropWrapper>
					<NameWrapper>
						{field.svg && (
							<ParcelSVG
								fill={computeColorFromConditions(field?.condition, "FIELD", COLORS.LAKE[25])}
								height={32}
								path={field.svg}
								stroke={computeColorFromConditions(field?.condition, "SLOT", COLORS.LAKE[100])}
								width={32}
							/>
						)}

						<div>
							<Name>{field.name}</Name>
							<Area>
								{convertToHa(field.fieldArea)} {t("units.hectare")} - {field.town}
							</Area>
						</div>
					</NameWrapper>
					<CropItem>
						<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={16} width={16} />
						<h5>{crop?.name}</h5>
					</CropItem>
				</NameCropWrapper>
				{field?.metrics ? (
					<div>
						<WeatherDetails metrics={field?.metrics} windSpeedUnity={windSpeedUnity} />
						<MetricsProductList metrics={field?.metrics} productMetrics={tankIndications?.productMetrics} />
						{field?.condition && (
							<h4 style={{ color: computeColorFromConditions(field?.condition, "TEXT") }}>
								{t(`conditions.${field?.condition}`)}
							</h4>
						)}
					</div>
				) : (
					<NoWeatherDetails>{t("components.fieldsReportCard.unavailableMeteo")}</NoWeatherDetails>
				)}
			</Content>
		</Wrapper>
	);
};

export default FieldCard;
