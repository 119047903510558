interface useSeasonsResult {
	currentSeason: { startAfter: Date; startBefore: Date; year: number };
	stepSeasons: { startAfter: Date; startBefore: Date; year: number }[];
}

interface useSeasonsProps {
	startDate?: Date;
	withNextSeason: boolean;
}

export const useSeasons = ({
	startDate = new Date(2019, 1, 1, 0, 0, 0),
	withNextSeason
}: useSeasonsProps): useSeasonsResult => {
	const computeSeasonYear = (date: Date): number =>
		date.getMonth() > 7 ? date.getFullYear() + 1 : date.getFullYear();
	const startSeasonYear = computeSeasonYear(startDate);
	const currentSeasonYear = computeSeasonYear(new Date());

	const accumulator = withNextSeason ? 2 : 1;

	const stepSeasons = Array(currentSeasonYear + accumulator - startSeasonYear)
		.fill(startSeasonYear)
		.map((year, index) => {
			const newYear = year + index;
			return {
				startAfter: new Date(newYear - 1, 8, 1),
				startBefore: new Date(newYear, 7, 31, 23, 59, 59, 999),
				year: newYear
			};
		});

	const currentSeason = stepSeasons.find((s) => s.year === currentSeasonYear);

	return { currentSeason, stepSeasons };
};
