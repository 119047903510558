import { Smag } from "@hygo/shared/icons";
import { Field } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { convertToHa } from "@hygo/shared/utils";
import { MouseEvent, RefObject } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Checkbox from "../../Checkbox";
import ParcelSVG from "../../ParcelSVG";
import FieldErrors from "./FieldErrors";

interface FieldProps {
	field: Field;
	fieldRef: RefObject<HTMLDivElement>;
	isSelectedField: boolean;
	onClick?: (e: MouseEvent<HTMLDivElement>, field: Field) => void;
	withCheckbox: boolean;
}

const FieldDetails = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const Name = styled.h4`
	flex: 1;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const Area = styled.h4<{ $isSelectedField: boolean }>`
	color: ${(props) => (props.$isSelectedField ? "var(--lake-100)" : "var(--night-50)")};
`;

const FieldSvg = styled(ParcelSVG)``;

const Wrapper = styled.div<{ $isSelectedField: boolean }>`
	padding: 8px;
	border-radius: 4px;
	cursor: pointer;
	color: ${(props) => (props.$isSelectedField ? "var(--lake-100)" : "var(--night-100)")};
	background: ${(props) => (props.$isSelectedField ? "var(--smoke-100)" : "var(--white-100)")};
	&:hover {
		color: var(--lake-100);
		${FieldSvg} path {
			stroke: var(--lake-100);
			fill: var(--lake-25);
		}
		${Area} {
			color: var(--lake-100);
		}
	}
`;

const Image = styled.img`
	width: 16px;
	height: 16px;
`;

const FieldCard = ({ field, fieldRef, isSelectedField, onClick, withCheckbox }: FieldProps): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Wrapper $isSelectedField={isSelectedField} key={field.id} onClick={(e) => onClick(e, field)} ref={fieldRef}>
			<FieldDetails>
				{withCheckbox && <Checkbox checked={isSelectedField} />}
				{field?.smagCropZoneUid && !field?.geofoliaReady && (
					<Smag backgroundFill={COLORS.TANGERINE[100]} height={16} width={16} />
				)}
				{field?.geofoliaReady && !field?.smagCropZoneUid && (
					<Image alt="geofolia" src={`/assets/images/oad/geofolia.png`} />
				)}
				{field?.smagCropZoneUid && field?.geofoliaReady && (
					<Image alt="geofolia" src={`/assets/images/oad/smag-geofolia.png`} />
				)}
				{field?.coordinates && (
					<FieldSvg
						fill={isSelectedField ? COLORS.LAKE[25] : COLORS.LAKE[25]}
						height={40}
						path={field.svg}
						stroke={isSelectedField ? COLORS.LAKE[100] : COLORS.LAKE[100]}
						width={40}
					/>
				)}

				<Name>{field.name}</Name>
				{!!field.area && (
					<Area $isSelectedField={isSelectedField}>
						{convertToHa(field.area)} {t("units.hectare")}
					</Area>
				)}
			</FieldDetails>
			{field.needCheck && (
				<FieldErrors
					hasArea={field?.area}
					hasCrop={field?.cropId}
					hasName={field?.name}
					hasZone={field?.coordinates}
				/>
			)}
		</Wrapper>
	);
};

export default FieldCard;
