import { AmplitudeContext } from "@hygo/shared/amplitude";
import { User as UserIcon } from "@hygo/shared/icons";
import { CoopUser, Item, NavbarEvents, User } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import { useContext, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import SelectInput from "../SelectInput";

interface UserSelectorProps {
	coopUsers: CoopUser[];
	signInAsUser: (v: number) => Promise<void>;
	user: User;
}

const UserSelector = ({ coopUsers, signInAsUser, user }: UserSelectorProps): JSX.Element => {
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { t } = useTranslation();
	const navigate = useNavigate();
	const defaultValues = useMemo(() => ({ user: user?.id?.toString() }), [user]);
	const methods = useForm({ defaultValues });

	useEffect(() => {
		methods.reset(defaultValues);
	}, [defaultValues, methods]);

	const onChange = async ({ label, value }: Item): Promise<void> => {
		await signInAsUser(parseInt(value, 10));
		logAnalyticEvent(NavbarEvents.loginAsUser, { name: label });
		navigate("/");
	};

	const username = (u: CoopUser | User): string => `${u.firstName} ${u.lastName}`;

	if (!coopUsers?.length) return null;
	return (
		<SelectInput
			control={methods.control}
			initialOptions={coopUsers?.map((u) => ({
				Icon: <UserIcon fill={COLORS.WHITE[100]} height={20} width={20} />,
				label: username(u),
				value: u.id.toString()
			}))}
			name="user"
			onCustomChange={onChange}
			placeholder={t("inputs.user.placeholder")}
			searchable
			theme="dark"
		/>
	);
};

export default UserSelector;
