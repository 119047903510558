import { useSeasons } from "@hygo/shared/hooks";
import { ArrowLeft, Idea, Parcelles } from "@hygo/shared/icons";
import { UserSmagStatus } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import {
	Button,
	EmptyState,
	FarmSelector,
	FormCard,
	FormHeader,
	InputTip,
	Loader,
	SelectInput
} from "@hygo/web/ui-components";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";

import { ImportSmagScreenProps } from "./screen.types";

const FarmSelectorInputTip = styled(InputTip)`
	margin-top: 8px;
	margin-bottom: 16px;
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
`;

const InputTipText = styled.h5`
	flex: 1;
`;

const NoSmagFarmsInputTip = styled(InputTip)`
	margin-top: 8px;
`;

const ColoredCardText = styled.h5`
	color: var(--night-50);
`;

const Wrapper = styled.div`
	display: flex;
	overflow: auto;
	height: 100%;
	background: var(--gradient-background-2);
`;

const Card = styled(FormCard)`
	margin: auto;
	box-shadow: 0px 6px 60px rgba(0, 83, 94, 0.05);
`;
const FlowWrapper = styled.div`
	margin-top: 32px;
`;

const RestrictedUserInputTip = styled(InputTip)`
	margin-bottom: 32px;
`;
const Step = styled.h4`
	margin-bottom: 16px;
`;
const Selectors = styled.div`
	background-color: var(--night-5);
	padding: 8px;
	border-radius: 4px;
	margin-bottom: 32px;
`;
const SmagFarmLoader = styled(Loader)`
	margin-top: 8px;
`;
const SmagFarmSelectorWrapper = styled.div`
	margin-top: 8px;
`;

const ImportSmagScreen = ({
	defaultFarm,
	farms,
	goBack,
	goToDashboard,
	loadingSmagFarms,
	methods,
	onChangeYear,
	onSearchFarms,
	onSubmit,
	smagFarms,
	updateDefaultFarm,
	userCoop,
	userSmagStatus
}: ImportSmagScreenProps): JSX.Element => {
	const { stepSeasons } = useSeasons({ withNextSeason: true });
	const { t } = useTranslation();
	const {
		formState: { isValid }
	} = methods;
	return (
		<Wrapper>
			{defaultFarm ? (
				<Card>
					<FormHeader
						backIcon={<ArrowLeft />}
						onGoBack={goBack}
						subTitle={t("screens.importSmag.subtitle")}
						title={t("screens.importSmag.title")}
					/>

					<>
						{userSmagStatus === UserSmagStatus.RESTRICTED_USER && (
							<FlowWrapper>
								<RestrictedUserInputTip palette={COLORS.DISABLED} withBorder={false}>
									<ColoredCardText>
										{t("screens.importSmag.restrictedUser.inputTip", { coop: userCoop?.name })}
									</ColoredCardText>
								</RestrictedUserInputTip>
								<Button
									color={COLORS.LAKE}
									onClick={goToDashboard}
									text={t("screens.importSmag.restrictedUser.btn")}
								/>
							</FlowWrapper>
						)}
						{userSmagStatus !== UserSmagStatus.RESTRICTED_USER && (
							<FlowWrapper>
								<Step>{t("screens.importSmag.steps.1.title")}</Step>
								<FarmSelector
									crudActions={false}
									defaultFarm={defaultFarm}
									farms={farms}
									updateDefaultFarm={updateDefaultFarm}
								/>
								<FarmSelectorInputTip palette={COLORS.GRASS}>
									<Idea fill={COLORS.GRASS[100]} height={20} width={20} />
									<InputTipText>{t("screens.importSmag.steps.1.inputTip")}</InputTipText>
								</FarmSelectorInputTip>
								<Step>{t("screens.importSmag.steps.2.title")}</Step>
								<Selectors>
									<SelectInput
										control={methods.control}
										initialOptions={stepSeasons.map((season) => ({
											label: t("inputs.season.formattedValue", { year: season.year }),
											value: season.year.toString()
										}))}
										label={t("inputs.season.label")}
										name="year"
										onCustomChange={(v) => onChangeYear(v?.value)}
										placeholder={t("inputs.season.placeholder")}
										rules={{
											required: {
												message: t("inputs.smagYear.errors.required"),
												value: true
											}
										}}
										theme="light"
									/>
									{smagFarms?.length > 0 ||
									(methods.watch().year && userSmagStatus === UserSmagStatus.SUPER_TC) ? (
										<SmagFarmSelectorWrapper>
											<SelectInput
												asyncFilter={
													userSmagStatus === UserSmagStatus.SUPER_TC && onSearchFarms
												}
												control={methods.control}
												initialOptions={smagFarms?.map((f) => ({
													label: f.name,
													value: f.uid
												}))}
												label={t("inputs.smagFarm.label")}
												loadingMessage={() =>
													t("screens.importSmag.smagFarmSelector.loadingMessage")
												}
												name="smagFarmId"
												placeholder={
													userSmagStatus === UserSmagStatus.SUPER_TC
														? t("inputs.smagFarm.superTC.placeholder")
														: t("inputs.smagFarm.user.placeholder")
												}
												rules={{
													required: {
														message: t("inputs.smagFarm.errors.required"),
														value: true
													}
												}}
												searchable={userSmagStatus === UserSmagStatus.SUPER_TC}
												shouldUnregister
												theme="light"
											/>
										</SmagFarmSelectorWrapper>
									) : (
										<>
											{loadingSmagFarms && <SmagFarmLoader />}
											{!loadingSmagFarms && methods.watch().year && (
												<NoSmagFarmsInputTip palette={COLORS.DISABLED} withBorder={false}>
													<Trans i18nKey="screens.importSmag.steps.2.errors">
														<ColoredCardText>
															<span aria-label="Attention" role="img">
																⚠️
															</span>
															No farm has been found for this season.
														</ColoredCardText>
														<ColoredCardText>
															<span aria-label="Infos" role="img">
																👉
															</span>
															Check your Smag account. If a farm exists on this season,
															contact us.
														</ColoredCardText>
													</Trans>
												</NoSmagFarmsInputTip>
											)}
										</>
									)}
								</Selectors>
								<Button
									color={COLORS.LAKE}
									disabled={!isValid}
									onClick={methods.handleSubmit(onSubmit)}
									text={t("screens.import.importBtn")}
								/>
							</FlowWrapper>
						)}
					</>
				</Card>
			) : (
				<EmptyState
					btnIcon={Parcelles}
					btnText={t("components.emptyState.noDefaultFarm.btn")}
					description={t("components.emptyState.noDefaultFarm.description")}
					illustration={<img alt="ferme" src={"/assets/images/coop.png"} width={100} />}
					onClick={goToDashboard}
					title={t("components.emptyState.noDefaultFarm.title")}
				/>
			)}
		</Wrapper>
	);
};

export default ImportSmagScreen;
