import { AmplitudeContext } from "@hygo/shared/amplitude";
import { UserContext } from "@hygo/shared/contexts";
import { AmbiguousCropWebModalPropsType, ConfirmationModalWebPropsType, ModalsContext } from "@hygo/shared/modals";
import { ImportEvents } from "@hygo/shared/models";
import { COLORS, denormalizeData } from "@hygo/shared/utils";
import { ImportedField } from "@hygo/web/models";
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useBlocker, useNavigate } from "react-router-dom";

interface useImportProps {
	AmbiguousCropModal?: FC<AmbiguousCropWebModalPropsType>;
	ConfirmationModal?: FC<ConfirmationModalWebPropsType>;
	fetchImportCallback?: () => Promise<ImportedField[]>;
	importCallback?: () => Promise<{
		createdFieldIds: number[];
		deletedFieldIds: number[];
		updatedFieldIds: number[];
	}>;
}

interface useImportResult {
	backFromError: () => void;
	cropSynchronize: boolean;
	error: boolean;
	fieldsToImport: ImportedField[];
	goBack: () => void;
	goBackToImportLanding: () => void;
	goToDashboard: () => void;
	importFields: () => Promise<void>;
	initImportLinking: () => Promise<void>;
	linking: { fieldId: number; importedFieldId: string; selected: boolean }[];
	loading: boolean;
	loadingImportedFields: boolean;
	onChange: ({ fieldId, importedFieldId }: { fieldId: number; importedFieldId: string }) => void;
	onClickCheckbox: (importedFieldId?: string) => void;
	retryImport: () => void;
	setCropSynchronize: (checked: boolean) => void;
	setFieldsToImport: Dispatch<SetStateAction<ImportedField[]>>;
	setLoadingImportedFields: Dispatch<SetStateAction<boolean>>;
	success: boolean;
}

const useImport = ({
	AmbiguousCropModal,
	ConfirmationModal,
	fetchImportCallback,
	importCallback
}: useImportProps): useImportResult => {
	const { t } = useTranslation();
	const blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			currentLocation.pathname !== nextLocation.pathname && !success && fieldsToImport?.length > 0
	);
	const navigate = useNavigate();
	const [loading, setLoading] = useState<boolean>(false);
	const [cropSynchronize, setCropSynchronize] = useState<boolean>(true);
	const [error, setError] = useState<boolean>(false);
	const [success, setSuccess] = useState<boolean>(false);
	const [fieldsToImport, setFieldsToImport] = useState<ImportedField[]>([]);
	const [linking, setLinking] = useState<{ fieldId: number; importedFieldId: string; selected: boolean }[]>(null);
	const [loadingImportedFields, setLoadingImportedFields] = useState<boolean>(true);
	const { activeFields, crops, defaultFarm, loadFields } = useContext(UserContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { showModal } = useContext(ModalsContext);

	const backFromError = (): void => setError(false);

	const retryImport = (): void => {
		backFromError();
		setLoading(true);
		logAnalyticEvent(ImportEvents.retryImport);
		importFields();
	};

	const goBack = async (): Promise<void> => navigate(-1);

	const goToDashboard = (): void => navigate("/");

	const onChange = ({ fieldId, importedFieldId }: { fieldId: number; importedFieldId: string }): void => {
		const newLinking = linking.map((l) => {
			if (l.fieldId === fieldId) return { ...l, fieldId: null };
			if (l.importedFieldId === importedFieldId) return { ...l, fieldId, importedFieldId };
			return l;
		});
		setLinking(newLinking);
	};

	const goBackToImportLanding = (): void => navigate("/import");

	const onClickCheckbox = (importedFieldId?: string): void => {
		if (importedFieldId) {
			setLinking((oldLinking) =>
				oldLinking?.map((l) => (l.importedFieldId === importedFieldId ? { ...l, selected: !l.selected } : l))
			);
		} else
			setLinking((oldLinking) =>
				oldLinking?.map((ol) => ({ ...ol, selected: !linking?.every((l) => l.selected) }))
			);
	};

	const createInitialLinking = useCallback(
		(importedFields: ImportedField[]): void => {
			const hygoFields = new Set(activeFields);
			const initialLinking = importedFields
				?.map((sf) => {
					const matchingNameAndAreaField = activeFields?.find((field) => {
						const percentageDifference = (Math.abs(field.area - sf.area) / field.area) * 100;
						return (
							percentageDifference >= 0 &&
							percentageDifference < 5 &&
							sf?.name?.toLowerCase() === field?.name?.toLowerCase() &&
							hygoFields.has(field)
						);
					});
					if (matchingNameAndAreaField) {
						hygoFields.delete(matchingNameAndAreaField);
						return { fieldId: matchingNameAndAreaField.id, importedFieldId: sf.id, selected: true };
					}
					return { fieldId: null, importedFieldId: sf.id, name: sf?.name, selected: true };
				})
				?.map((sf) => {
					const matchingNameField = activeFields?.find(
						(field) => hygoFields.has(field) && sf?.name?.toLowerCase() === field?.name?.toLowerCase()
					);
					if (matchingNameField) {
						hygoFields.delete(matchingNameField);
						return { fieldId: matchingNameField.id, importedFieldId: sf.importedFieldId, selected: true };
					}
					return sf;
				});

			setLinking(initialLinking);
		},
		[activeFields]
	);

	const initImportLinking = useCallback(async (): Promise<void> => {
		setLoadingImportedFields(true);
		const fetchedImportedFields = await fetchImportCallback();
		setFieldsToImport(fetchedImportedFields);
		createInitialLinking(fetchedImportedFields);
		setLoadingImportedFields(false);
	}, [createInitialLinking, fetchImportCallback]);

	const importFields = async (): Promise<void> => {
		try {
			setLoading(true);
			const response = await importCallback();
			if (!response) return;

			setSuccess(true);
			const fetchedFields = await loadFields(false, defaultFarm?.id);

			const ambiguousCropIds = [
				...new Set(
					fetchedFields
						.filter(
							(field) =>
								[...response.createdFieldIds, ...response.updatedFieldIds].find(
									(id) => id === field.id
								) &&
								field.needCropCheck &&
								field.cropId
						)
						?.map((f) => f.cropId)
				)
			];
			if (ambiguousCropIds?.length > 0)
				showModal(AmbiguousCropModal, {
					crops: denormalizeData({ data: crops, usages: ambiguousCropIds })
				});
		} catch (e) {
			setError(true);
			throw e;
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (blocker?.state === "blocked") {
			showModal(ConfirmationModal, {
				btnColorPalette: COLORS.GASPACHO,
				confirmLabel: t("button.yes"),
				dismissLabel: t("button.no"),
				handleConfirm: async () => blocker.proceed(),
				handleDismiss: () => blocker.reset(),
				subtitle: t("modals.cancelImport.body"),
				title: t("modals.cancelImport.title")
			});
		}
	}, [blocker, ConfirmationModal, showModal, t]);

	return {
		backFromError,
		cropSynchronize,
		error,
		fieldsToImport,
		goBack,
		goBackToImportLanding,
		goToDashboard,
		importFields,
		initImportLinking,
		linking,
		loading,
		loadingImportedFields,
		onChange,
		onClickCheckbox,
		retryImport,
		setCropSynchronize,
		setFieldsToImport,
		setLoadingImportedFields,
		success
	};
};

export default useImport;
