import { Crop, DenormalizedField } from "@hygo/shared/models";
import { COLORS, computeColorFromConditions, convertToHa } from "@hygo/shared/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import ParcelSVG from "../../ParcelSVG";

interface FieldCardProps {
	crops: Crop[];
	field: DenormalizedField;
	isSelected: boolean;
}

const Wrapper = styled.div<{ $isSelectedField: boolean }>`
	width: 420px;
	box-shadow:
		0px 0.8px 1.5px rgba(0, 83, 94, 0.1),
		0px 6px 12px rgba(0, 83, 94, 0.1);
	border-radius: 4px;
	opacity: ${(props) => (props.$isSelectedField ? 1 : 0.25)};
	display: inline-block;
	margin-right: 16px;
	&:last-child {
		margin-right: 0;
	}
`;

const Content = styled.div`
	background-color: var(--white-100);
	padding: 16px;
	border-radius: 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const CropItem = styled.div`
	padding: 8px 16px;
	display: flex;
	gap: 8px;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	background-color: var(--night-5);
`;

const NameWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 16px;
`;

const Name = styled.h4`
	color: var(--night-100);
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	width: 120px;
`;

const Area = styled(Name)`
	color: var(--night-50);
`;

const FieldCard = ({ crops, field, isSelected }: FieldCardProps): JSX.Element => {
	const { t } = useTranslation();
	const crop = crops.find((c) => c.id === field?.cropId);

	return (
		<Wrapper $isSelectedField={isSelected}>
			<Content>
				<NameWrapper>
					{field.svg && (
						<ParcelSVG
							fill={computeColorFromConditions(field?.condition, "FIELD", COLORS.LAKE[25])}
							height={32}
							path={field.svg}
							stroke={computeColorFromConditions(field?.condition, "SLOT", COLORS.LAKE[100])}
							width={32}
						/>
					)}

					<div>
						<Name>{field.name}</Name>
						<Area>
							{convertToHa(field.fieldArea || field.area)} {t("units.hectare")} - {field.town}
						</Area>
					</div>
				</NameWrapper>
				<CropItem>
					<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={16} width={16} />
					<h5>{crop?.name}</h5>
				</CropItem>
			</Content>
		</Wrapper>
	);
};

export default FieldCard;
