import { UserContext } from "@hygo/shared/contexts";
import { Crop, Field, RPGField } from "@hygo/shared/models";
import { CreationMode, DashboardMode } from "@hygo/web/models";
import { fitMapBounds } from "@hygo/web/utils";
import {
	createContext,
	createRef,
	Dispatch,
	RefObject,
	SetStateAction,
	useCallback,
	useContext,
	useMemo,
	useState
} from "react";

interface CropsScreenContextResult {
	creationMode: CreationMode;
	currentMode: DashboardMode;
	editedFieldId: number;
	fieldCoordinates: { lat: number; lon: number }[];
	fieldsRef: { [x: number]: RefObject<HTMLDivElement> };
	handleFieldSelection: (data: {
		centerMap: boolean;
		field: Field;
		overrideActiveFields: Field[];
		overrideNewSelectedFields: Field[];
		selection: boolean;
	}) => void;
	map: google.maps.Map;
	RPGFields: RPGField[];
	selectedCrop: Crop;
	selectedFields: Field[];
	setCreationMode: Dispatch<SetStateAction<CreationMode>>;
	setCurrentMode: Dispatch<SetStateAction<DashboardMode>>;
	setEditedFieldId: Dispatch<SetStateAction<number>>;

	setFieldCoordinates: Dispatch<SetStateAction<{ lat: number; lon: number }[]>>;
	setMap: Dispatch<SetStateAction<google.maps.Map>>;
	setRPGFields: Dispatch<SetStateAction<RPGField[]>>;
	setSelectedCrop: Dispatch<SetStateAction<Crop>>;
}

export const CropsScreenContext = createContext({} as CropsScreenContextResult);
interface CropsScreenProviderProps {
	children: JSX.Element | JSX.Element[];
}

const CropsScreenProvider = ({ children }: CropsScreenProviderProps): JSX.Element => {
	const [selectedFields, setSelectedFields] = useState<CropsScreenContextResult["selectedFields"]>([]);
	const [selectedCrop, setSelectedCrop] = useState<CropsScreenContextResult["selectedCrop"]>();
	const [map, setMap] = useState<CropsScreenContextResult["map"]>(null);
	const [RPGFields, setRPGFields] = useState<CropsScreenContextResult["RPGFields"]>([]);
	const [fieldCoordinates, setFieldCoordinates] = useState<CropsScreenContextResult["fieldCoordinates"]>([]);
	const [creationMode, setCreationMode] = useState<CropsScreenContextResult["creationMode"]>(CreationMode.RPG);
	const [currentMode, setCurrentMode] = useState<CropsScreenContextResult["currentMode"]>(DashboardMode.FIELD_LIST);
	const [editedFieldId, setEditedFieldId] = useState<CropsScreenContextResult["editedFieldId"]>(null);

	const { activeFields, user } = useContext(UserContext);

	const fieldsRef: CropsScreenContextResult["fieldsRef"] = activeFields?.reduce(
		(acc: { [x: number]: RefObject<HTMLDivElement> }, value) => {
			acc[value.id] = createRef();
			return acc;
		},
		{}
	);

	const polygonCoordinates = [...fieldCoordinates, fieldCoordinates[0]]?.filter((n) => n);

	const scrollToSelectedField = useCallback(
		(fieldId: number): void => {
			if (fieldId && fieldsRef?.[fieldId].current) {
				fieldsRef[fieldId].current.scrollIntoView({
					behavior: "smooth",
					block: "nearest"
				});
			}
		},
		[fieldsRef]
	);

	const handleFieldSelection: CropsScreenContextResult["handleFieldSelection"] = useCallback(
		({ centerMap, field, overrideActiveFields, overrideNewSelectedFields, selection }) => {
			const isSelectedField = selectedFields?.find((selected) => selected.id === field?.id);
			let result;
			if (!selection) {
				result = isSelectedField ? [] : [field];
				scrollToSelectedField(field?.id);
			} else if (isSelectedField) {
				result = selectedFields?.filter((fd) => fd.id !== field?.id);
			} else {
				result = [...selectedFields.filter((fd) => fd.id !== field?.id), field];
				scrollToSelectedField(field?.id);
			}
			const newSelectedFields = overrideNewSelectedFields || result;
			const newActiveFields = (overrideActiveFields || activeFields)?.filter((f) => !f.deleted);

			setSelectedFields(newSelectedFields);
			if (map && centerMap)
				fitMapBounds({
					fields:
						newSelectedFields?.filter((c) => c.coordinates?.length > 0)?.length > 0
							? newSelectedFields
							: newActiveFields,
					map,
					userLocation: user?.location
				});
		},
		[activeFields, selectedFields, scrollToSelectedField, user, map]
	);

	const value = useMemo(
		() => ({
			creationMode,
			currentMode,
			editedFieldId,
			fieldCoordinates: polygonCoordinates,
			fieldsRef,
			handleFieldSelection,
			map,
			RPGFields,
			selectedCrop,
			selectedFields,
			setCreationMode,
			setCurrentMode,
			setEditedFieldId,
			setFieldCoordinates,
			setMap,
			setRPGFields,
			setSelectedCrop
		}),
		[
			RPGFields,
			map,
			creationMode,
			selectedFields,
			handleFieldSelection,
			fieldsRef,
			selectedCrop,
			polygonCoordinates,
			editedFieldId,
			currentMode
		]
	);

	return <CropsScreenContext.Provider value={value}>{children}</CropsScreenContext.Provider>;
};

export default CropsScreenProvider;
