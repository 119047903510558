export enum NozzleColor {
	BLUE = "blue",
	BROWN = "brown",
	GREEN = "green",
	GREY = "grey",
	ORANGE = "orange",
	PURPLE = "lilas",
	RED = "red",
	YELLOW = "yellow"
}
