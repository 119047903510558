export enum AuthEvents {
	deletion = "Suppression de compte",
	login = "Connexion",
	logout = "Déconnexion",
	logoutFromError = "Déconnexion suite à une erreur"
}

export enum HomeEvents {
	clickAddIrrigation = "Clic sur “Irrigation” depuis la modal de création de traitement",
	clickAddMixture = "Bouton 'Ajouter une bouillie' depuis l'Accueil",
	clickAddTreatment = "Clic sur “Protection” depuis la modal de création de traitement",
	clickContactUs = "Clic sur Intercom depuis l’accueil",
	clickDeleteMixture = "Clic sur “Supprimer le traitement” depuis la modal bouillie",
	clickGoNetatmo = "Clic sur “En Direct” depuis l’accueil",
	clickGoToMileos = "Clic sur Mileos",
	clickGoToRadar = "Clic sur l'écran radar depuis l'Accueil",
	clickGoToRealTime = "Clic sur l'écran temps réel depuis l'Accueil",
	clickLoadMore = "Clique sur 'Charger plus'",
	clickMeteo = "Clic sur la météo depuis l'Accueil",
	clickMixtureDay = "Clic sur le détail d’un jour depuis une bouillie",
	clickOpenMixtureMenu = "Ouverture de la modal d’action des bouillies",
	clickShowHomologatedDoses = "Clic sur “Voir les préconisations d’application” depuis la modal bouillie",
	clickTask = "Clic sur une intervention depuis un traitement",
	clickUpdateMixture = "Clic sur 'Modifier le traitement' depuis la modal bouillie",
	initDoneTask = "Clic sur 'Ajouter une intervention réalisée' depuis la modal bouillie",
	maxMixturesAttempts = "Clic sur “C’est compris” depuis l’écran maximum de bouillie",
	selectFarm = "Clic sur sélection de ferme",
	swipeToRefresh = "Recharge les traitements “pull to refresh” de l’accueil"
}

export enum NetatmoRainEvents {
	centerLocation = "Clic sur sa localisation depuis l’écran Météo en direct",
	clickBetaButton = "Clic sur “En développement” depuis l’écran Météo en direct",
	clickNextDayRain = "Clic sur pluie “Aujourd’hui” depuis l’écran Météo en direct",
	clickNextHourRain = "Clic sur pluie “Dans l’heure” depuis l’écran Météo en direct",
	goBack = "Clic sur retour depuis l’écran Météo en direct",
	selectNewDataType = "Choix d'un nouveau type de données à afficher"
}

export enum MileosSettingsEvents {
	clickCampaignExport = "Clic sur “Exporter ma campagne”",
	clickSendSms = "Active / Désactive les alertes SMS Mileos",
	goToAdvisors = "Clic sur “Gérer mes conseillers”"
}

export enum MileosAdvisorDetailsEvents {
	sendInvitation = "Clic sur “Envoyer une invitation”"
}

export enum SmartlookEvents {
	sessionUrl = "Lancement de la session Smartlook"
}

export enum MixtureInfosEvents {
	clickNotice = "Clic sur une fiche fabricant",
	clickOnCrop = "Clic sur les détail d’usage d’une culture",
	clickOnSearchBar = "Clic sur la barre de recherche de culture depuis l’écran des usages"
}

export enum MixtureEvents {
	clickCreateMixture = "Création d'une bouillie",
	clickRisk = "Clic sur un risque",
	clickUpdateMixtureFromHome = "Modification des produits depuis une bouillie",
	duplicateMixture = "Doublon de traitement",
	editProducts = "Modification des produits depuis l'écran de création de bouillies"
}

export enum DrawerEvents {
	clickGoToNotifications = "Clic sur notifications",
	clickHelp = "Clic sur Guide d'utilisation",
	goToDiscoverLanding = "Clic sur 'Découvrir HYGO'",
	goToEquipment = "Clic sur 'Mes équipement et sol'",
	goToSettings = "Clic sur 'Paramètres'",
	goToUserSelector = "Clic sur changer d'utilisateur"
}

export enum TaskProductsEvents {
	clickMissingProduct = "Clic sur Confirmer depuis l’écran produit manquant",
	clickOnDiscoverHygoPremium = "Clic sur Découvrir Hygo Premium depuis la modale du pH",
	clickOnPhInfo = "Clic sur Produit sensible au pH dans l’écran de création des bouillies",
	clickOnRecommendation = "Clic sur recommandation depuis l'écran Sélection de produits",
	editDebit = "Modification du debit de la tâche depuis l'écran Sélection des produits",
	editNozzle = "Modification de la buse de la tâche depuis l'écran Sélection des produits",
	editProducts = "Modification des produits depuis l'écran 'Sélection de produits'",
	editSprayer = "Modification du pulvérisateur de la tâche depuis l'écran Sélection des produits",
	goToMixtureInfos = "Clic sur les doses homologuées depuis la modal de saisie de dose",
	toggleDoseType = "Clic sur le choix de la dose en litre",
	validateProducts = "Clic sur bouton 'Confirmer' depuis l'écran Sélection de produits"
}

export enum TaskFieldsEvents {
	clickOnConfirmFieldAreaModal = "Modification de la surface traitée",
	clickOnTreatedArea = "Clic sur la surface traitée",
	editFields = "Modification des parcelles depuis l'écran 'Sélection de parcelles'",
	filterFieldsByProducts = "Activer/désactiver le filtre par culture autorisées",
	selectSlot = "Modification de la durée du créneau",
	validateFields = "Clic sur le bouton Confirmer depuis l’écran Sélection de parcelles"
}

export enum TaskSlotEvents {
	clickParamsHelper = "Clic sur Quels sont ces paramètres ?",
	clickSpecificConditions = "Clic sur conditions spécifiques pour les afficher",
	showExplicabilityBars = "Affiche les barres d’explicabilité depuis le slider",
	validateSlot = "Validation du créneau de l'écran Sélection du créneau"
}

export enum TaskReportEvents {
	deleteTask = "Suppression de la tâche depuis l'écran Résumé de Pulvérisation",
	editDebit = "Modification du débit depuis l'écran Résumé de pulvérisation",
	editModulation = "Activation/Désactivation de la modulation depuis l'écran Résumé de pulvérisation",
	editNotes = "Modification des notes depuis l'écran Résumé de pulvérisation",
	editNozzle = "Modification de la buse depuis l'écran Résumé de pulvérisation",
	editSprayer = "Modification du pulvérisateur depuis l'écran Résumé de pulvérisation",
	exportSmagTask = "Export de la tâche vers Smag",
	goToFields = "Redirection vers la modification des parcelles depuis l'écran Résumé de pulvérisation",
	goToMileosInfos = "Clic sur “Infos produits & rémanence” depuis une intervention",
	goToProducts = "Redirection vers la modification des produits depuis l'écran Résumé de pulvérisation",
	goToSlot = "Redirection vers la modification de créneau depuis l'écran Résumé de pulvérisation",
	goToTargets = "Redirection vers la modification des cibles depuis l'écran Résumé de pulvérisation",
	markAsDone = "Clic sur Marqué comme réalisé",
	saveReport = "Sauvegarde de la tâche depuis l'écran Résumé de pulvérisation",
	updateMileosInfos = "Modification de la rémanence"
}

export enum MileosPhasesEvents {
	editDevelopmentPhases = "Modification d’un stade de développement"
}

export enum FieldPhasesEvents {
	editHarvest = "Clic sur la modification de la date de récolte",
	editSowing = "Clic sur la modification de la date de semis"
}

export enum MileosEvents {
	clickAddInfo = "Clic sur ”Ajouter une information” depuis une parcelle Mileos",
	clickAddIrrigation = "Clic sur “Irrigation” depuis la modal d’ajout d’informations",
	clickAddMildiou = "Clic sur “Observation de mildiou” depuis la modal d’ajout d’informations",
	clickAddProtection = "Clic sur “Protection” depuis la modal d’ajout d’informations",
	clickEditPhases = "Clic sur “Stades de développement” depuis la modal d’ajout d’informations",
	clickMissingFieldsSetup = "Clic sur “Des parcelles ne sont pas configurées” depuis Mileos",
	clickSetupFields = "Clic sur “Configurer mes parcelles” depuis l’empty state",
	goToFieldSettings = "Accès aux paramètres d’une parcelle depuis Mileos",
	goToRainFall = "Clic sur “Corriger” depuis le détail des indices Mileos",
	goToSettings = "Clic sur paramètres Mileos",
	ignoreAdvice = "Ignorer un traitement",
	navigateInIndices = "Modification de la période observée depuis le détail des indices Mileos",
	showIndicesModal = "Clic sur le détail des indices Mileos"
}

export enum MileosMilidouEvents {
	addMildiou = "Clic sur “Ajouter une observation” depuis les observations Mildiou",
	editMildiou = "Modifier une observation depuis les observations Mildiou"
}

export enum MileosRainFallEvents {
	editRainFall = "Modifier la valeur de pluie Mileos"
}

export enum MileosAdvisorFieldsEvents {
	addField = "Ajout d'une parcelle attribuée à un conseiller",
	removeField = "Suppression d'une parcelle attribuée à un conseiller"
}

export enum TasksListEvents {
	clickCenterMap = "Clic sur l’icône de centrage de la carte dans les travaux réalisés",
	clickExpandMap = "Clic sur l’icône d’agrandissement/réduction de la taille de la carte dans les travaux réalisés",
	clickMapField = "Clic sur une parcelle depuis la carte des travaux réalisés",
	clickTask = "Sélection d'une tâche"
}

export enum DetectedTasksEvents {
	clickSelectSlot = "Clic sur 'Choisir ce créneau' depuis l'écran de tâches détectées",
	clickTaskCard = "Clic sur une détection depuis l'écran de tâches détectées'"
}

export enum FieldSettingsEvents {
	editFieldName = "Clic sur Renommer une parcelle",
	editPlantationDate = "Modification de la date de plantation",
	editYield = "Modification du rendement",
	editZone = "Clic sur “Modifier la zone” depuis Mes parcelles",
	goBackToFields = "Clic sur “Terminer”",
	goToMileosPhases = "Clic sur “Stades de développement” depuis la parcelle",
	goToPhases = "Clic sur “Dates de semi & récolte” depuis la parcelle"
}

export enum MileosVarietySelectorEvents {
	editVariety = "Modification de la variété"
}

export enum MileosAdvisorEvents {
	addAdvisor = "Clic sur “Ajouter un conseiller",
	showAdvisor = "Clic sur un conseiller Mileos"
}

export enum ArvalisDeviceEvents {
	addStation = "Ajout d’une station"
}

export enum ArvalisDeviceSelectorEvents {
	deleteStation = "Suppression d’une station",
	updateStation = "Modification d'une station"
}

export enum FieldsEvents {
	clickAddField = "Clic sur “Ajouter une parcelle” depuis Mes parcelles",
	clickGoBackHome = "Clic sur “Non” à la demande d’ajout de parcelles supplémentaires",
	clickMoreFields = "Clic sur “Oui” à la demande d’ajout de parcelles supplémentaires",
	fromGeofolia = "Clic sur “Geofolia” depuis l’ajout de parcelle",
	fromMesParcelles = "Clic sur “Mes Parcelles” depuis l’ajout de parcelle",
	fromTelepac = "Clic sur “Telepac” depuis l’ajout de parcelle",
	goToField = "Sélection d’une parcelle depuis “Mes parcelles”",
	selectFromMap = "Clic sur “Sélectionner sur la carte” depuis l’ajout de parcelle",
	updateCrops = "Mise à jour des parcelles depuis l'écran Mes Parcelles"
}

export enum RPGEvents {
	selectRPGField = "Sélection d’un contour",
	validateZone = "Clic sur “Valider la zone”"
}

export enum AdvancedEquipmentEvents {
	editSoilAcidity = "Clic sur la modification de l’acidité du sol",
	editWaterAcidity = "Clic sur la correction de l’acidité de l’eau",
	editWaterHardness = "Clic sur la correction de la dureté de l’eau",
	editWaterTankBottomPercentage = "Clic sur la correction du volume d’eau",
	validateAdvancedEquipment = "Enregistrement d’une configuration avancée"
}

export enum OnboardingEvents {
	onboardingEnd = "Fin de l'onboarding",
	onboardingStart = "Début de l'onboarding général",
	skipOnboarding = "Passe l’onboarding"
}

export enum RealTimeEvents {
	selectSprayer = "Changement de pulvérisateur depuis le temps réel"
}

export enum NotificationEvents {
	clickOnDetectedTask = "Clic sur 'Passage détecté'",
	clickOnMileosCurativeFungicide = "Clic sur une alerte de préconisation de traitementement curatif de Miléos",
	clickOnMileosEradicatingFungicide = "Clic sur une alerte de préconisation traitement éradicant de Miléos",
	clickOnMileosTreatmentNeeded = "Clic sur une alerte traitement de Miléos",
	clickOnSprayerEnabled = "Clic sur 'Votre pulvérisateur a démarré'",
	clickOnTask = "Clic sur une intervention depuis la notif de dégradation des conditions"
}

export enum PlanPresentationEvents {
	clickTryHygoPremium = "Clic sur 'Démarrer un essai gratuit'",
	playVideo = "Lecture de la vidéo"
}

export enum DiscoverLandingEvents {
	clickOnPlan = "Clic sur un plan premium"
}

export enum SettingsEvents {
	switchHveMode = "Activer/désactiver le mode expert"
}

export enum RadarEvents {
	goBackFromRadar = "Clic sur retour depuis l’écran radar",
	pauseRadar = "Mettre en pause le radar"
}

export enum NozzleEvents {
	addNozzle = "Ajout d’une buse",
	deleteNozzle = "Suppression d’une buse",
	updateNozzle = "Modification d’une buse"
}

export enum EquipmentEvents {
	firstSetup = "Première configuration de l'équipement réalisée (sol & buses)",

	goToAdvancedEquipment = "Clic sur 'Configuration avancée'",
	updateSoil = "Modification du sol"
}

export enum NavbarEvents {
	goToAccount = "Clic sur “Mes infos & abonnement”",
	goToFieldsManager = "Clic sur l’onglet “Parcellaire”",
	goToImport = "Clic sur l’onglet “Importer”",
	goToOAD = "Clic sur l'onglet “Mes outils connectés”",
	goToSprayers = "Clic sur l’onglet Pulvérisateurs",
	goToTasks = "Clic sur l’onglet “Interventions”",
	loginAsUser = "Sélection d’un utilisateur"
}

export enum CheckSetupEvents {
	needUpdate = "Arrivée sur l'écran de blocage 'Mettre à jour l'application'",
	noDefaultFarm = "Arrivée sur l'écran de blocage 'Pas de ferme pas défaut'",
	noFields = "Arrivée sur l'écran de blocage 'Pas de parcelles dans cette ferme'",
	noNetwork = "Arrivée sur l'écran de blocage 'Pas de réseau'",
	noPlan = "Arrivée sur l'écran de blocage 'Pas d'abonnement trouvé'",
	noWeather = "Arrivée sur l'écran de blocage 'Erreur de chargement météo'",
	onGoingMaintenance = "Arrivée sur l’écran de blocage 'Maintenance en cours'"
}

export enum ImportEvents {
	clickImportSmag = "Clic sur “Import via Smag”",
	clickImportTelepac = "Clic sur “Import depuis un fichier”",
	importFromFieldsLinking = "Valide l'import depuis l'écran d'association des parcelles",
	retryImport = "Réessayer l’import sur à une erreur"
}

export enum FieldsManagerEvents {
	clickDrawNewField = "Clic sur “Nouvelle parcelle”",
	clickEditZone = "Clic sur “Redessiner la zone”",
	clickShowTasks = "Clic sur “Voir interventions” depuis une parcelle sélectionnée dans “Parcellaire”",
	createFarm = "Création d'une ferme",
	createFields = "Clic sur “Créer ces parcelles”",
	deleteFarm = "Suppression d’une ferme",
	deleteField = "Suppression d’une parcelle",
	editFieldCharacteristics = "Clic sur modifier les caractéristique de parcelle",
	editFieldName = "Clic sur Renommer une parcelle",
	editFieldPhases = "Clic sur modifier le cycle agricole",
	selectFieldFromManager = "Sélection d’une parcelle depuis le manager",
	selectFieldFromMap = "Sélection d’une parcelle depuis la carte",
	updateDefaultFarm = "Sélection d’une ferme depuis le parcellaire",
	updateFarm = "Modification d’une ferme",
	updateField = "Modification des infos d’une parcelle",
	updateFieldsCrops = "Sélection d’une culture depuis la modification de culture",
	validateFieldsCrops = "Valider la sélection depuis la modification de culture"
}

export enum TasksEvents {
	clickTaskDetails = "Clic sur le détail d’une intervention",
	deleteTask = "Suppression d’une intervention",
	exportTasksFileEdi = "Export des intervention en .edi",
	exportTasksFileXsl = "Export des intervention en .xlsx",
	filterTasksWithField = "Sélection d’une parcelle depuis “Interventions”",
	updateDefaultFarmFromTasks = "Sélection d’une ferme depuis les interventions",
	updateSeasonFromTasks = "Sélection d’une saison depuis les interventions"
}

export enum SprayerEvents {
	addNozzle = "Ajout d'une buse depuis l'écran Pulvérisateurs",
	createSprayer = "Ajout d’un pulvérisateur",
	deleteNozzle = "Suppression d'une buse depuis l'écran Pulvérisateurs",
	deleteSprayer = "Suppression d’un pulvérisateur",
	updateNozzle = "Modification d'une buse depuis l'écran Pulvérisateurs",
	updateSprayer = "Modification d’un pulvérisateur"
}

export enum AccountEvents {
	addMorePlots = "Clic sur “Ajouter mon parcellaire en passant par un essai gratuit”",
	clickManageSubscription = "Clic sur 'Gérer mon abonnement'",
	deleteCoop = "Supprime un distributeur",
	editLocation = "Clic sur “Ajouter une commune”",
	editPassword = "Clic sur la modification du mot de passe",
	goToPricing = "Clic sur “Consulter les offres HYGO”",
	updateCoop = "Modification du distributeur",
	updateEmail = "Modification de l'email",
	updateFirstName = "Modification du prénom",
	updateLastName = "Modification du nom",
	updateLocation = "Modification de la commune de l’exploitation",
	updatePassword = "Valide la modification du mot de passe",
	updatePhone = "Modification du numéro de téléphone",
	updatePhoneCountry = "Modifie l’indicatif de téléphone",
	updateUser = "Clic sur “Enregistrer” depuis mes informations",
	updateZipCode = "Modification du code postal"
}

export enum OADEvents {
	clickLoginToSmag = "Clic sur “Me connecter” sur l'OAD Smag"
}

export type AmplitudeEvent =
	| AccountEvents
	| AdvancedEquipmentEvents
	| ArvalisDeviceEvents
	| ArvalisDeviceSelectorEvents
	| AuthEvents
	| CheckSetupEvents
	| DetectedTasksEvents
	| DiscoverLandingEvents
	| DrawerEvents
	| EquipmentEvents
	| FieldPhasesEvents
	| FieldSettingsEvents
	| FieldsEvents
	| FieldsManagerEvents
	| HomeEvents
	| ImportEvents
	| MileosAdvisorDetailsEvents
	| MileosAdvisorEvents
	| MileosAdvisorFieldsEvents
	| MileosEvents
	| MileosMilidouEvents
	| MileosPhasesEvents
	| MileosRainFallEvents
	| MileosSettingsEvents
	| MileosVarietySelectorEvents
	| MixtureEvents
	| MixtureInfosEvents
	| NavbarEvents
	| NetatmoRainEvents
	| NotificationEvents
	| NozzleEvents
	| OADEvents
	| OnboardingEvents
	| PlanPresentationEvents
	| RadarEvents
	| RealTimeEvents
	| RPGEvents
	| SettingsEvents
	| SmartlookEvents
	| SprayerEvents
	| TaskFieldsEvents
	| TaskProductsEvents
	| TaskReportEvents
	| TasksEvents
	| TasksListEvents
	| TaskSlotEvents;
