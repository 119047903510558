import { useSignup } from "@hygo/shared/feature-authentication";
import { Country, Language, UserLevel } from "@hygo/shared/models";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { SignupAgriScreenProps } from "./screen.types";
import SignupAgriScreen from "./SignupAgriScreen";

const SignupAgriContainer = (): JSX.Element => {
	const location = useLocation();
	const level = location?.state ? location?.state?.level : UserLevel.FARMER;
	const { i18n } = useTranslation();
	const {
		activeStep,
		coops,
		createNewUser,
		goNextStep,
		goPreviousStep,
		isButtonDisabled,
		loading,
		methods,
		onChangeCountry,
		onEditPhoneCountrySelector,
		passwordShown,
		PasswordVisibilityIcon,
		rules,
		togglePasswordVisibility
	} = useSignup({ defaultCountry: Country.FR, language: i18n.resolvedLanguage as Language, level });
	const navigate = useNavigate();
	const onGoBack: SignupAgriScreenProps["onGoBack"] = () => navigate(-1);

	return (
		<SignupAgriScreen
			activeStep={activeStep}
			coops={coops}
			goNextStep={goNextStep}
			goPreviousStep={goPreviousStep}
			isButtonDisabled={isButtonDisabled}
			level={level}
			loading={loading}
			methods={methods}
			onChangeCountry={onChangeCountry}
			onClickCreateAccount={createNewUser}
			onEditPhoneCountrySelector={onEditPhoneCountrySelector}
			onGoBack={onGoBack}
			passwordShown={passwordShown}
			PasswordVisibilityIcon={PasswordVisibilityIcon}
			rules={rules}
			togglePasswordVisibility={togglePasswordVisibility}
		/>
	);
};

export default SignupAgriContainer;
