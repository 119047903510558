import { useNavigate } from "react-router-dom";

import { SignupLandingScreenProps } from "./screen.types";
import SignupLandingScreen from "./SignupLandingScreen";

const SignupLandingContainer = (): JSX.Element => {
	const navigate = useNavigate();

	const onGoBack: SignupLandingScreenProps["onGoBack"] = () => navigate(-1);

	const goToAgriFlow: SignupLandingScreenProps["goToAgriFlow"] = (level) => navigate("agri", { state: { level } });

	return <SignupLandingScreen goToAgriFlow={goToAgriFlow} onGoBack={onGoBack} />;
};

export default SignupLandingContainer;
