import { NoZone } from "@hygo/shared/icons";
import { Crop } from "@hygo/shared/models";
import { COLORS, convertToHa } from "@hygo/shared/utils";
import { convertCoords } from "@hygo/shared/utils";
import { ImportedField } from "@hygo/web/models";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import CropIcon from "../../CropIcon";
import ParcelSVG from "../../ParcelSVG";
import TooltipBubble from "../../TooltipBubble";

interface FieldCardProps {
	crops: Crop[];
	field: ImportedField;
}

const SelectorTooltip = styled(TooltipBubble)`
	top: 58px;
	z-index: 2;
`;

const Wrapper = styled.div`
	height: 52px;
	background-color: var(--white-100);
	border: 1px solid var(--night-10);
	border-radius: 4px;
	position: relative;
	width: 50%;
	display: flex;
	align-items: center;
	/* flex: 1; */
	gap: 8px;
	padding: 0 8px;
	&:hover ${SelectorTooltip} {
		display: block;
	}
`;

const DetailsWrapper = styled.div`
	flex: 1;
	overflow: hidden;
`;

const SvgWrapper = styled.div`
	position: relative;
`;

const CropIconWrapper = styled.div`
	width: 16px;
	height: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-color: var(--white-80);
	bottom: 0;
	right: 0;
	position: absolute;
`;

const FieldName = styled.h4`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;

const Subtitle = styled.h5`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	color: var(--night-50);
`;

const FieldCard = ({ crops, field }: FieldCardProps): JSX.Element => {
	const { t } = useTranslation();
	const coords = convertCoords(field.coordinates);
	const crop = crops.find((c) => c.id === field?.cropId);

	return (
		<Wrapper>
			<SvgWrapper>
				{coords ? (
					<ParcelSVG fill={COLORS.LAKE[25]} height={32} path={coords} stroke={COLORS.LAKE[100]} width={32} />
				) : (
					<NoZone fill={COLORS.NIGHT[100]} height={32} width={32} />
				)}
				{crop && (
					<CropIconWrapper>
						<CropIcon crop={crop} fill={COLORS.NIGHT[100]} height={12} width={12} />
					</CropIconWrapper>
				)}
			</SvgWrapper>
			<DetailsWrapper>
				<FieldName>{field.name}</FieldName>
				<Subtitle>
					{field?.area &&
						`${convertToHa(field?.area)} ${t("units.hectare")}${field?.town ? ` - ${field.town}` : ""}`}
				</Subtitle>
			</DetailsWrapper>
			{crop && <SelectorTooltip text={crop?.name} />}
		</Wrapper>
	);
};

export default FieldCard;
