import { AmplitudeContext } from "@hygo/shared/amplitude";
import { useApi } from "@hygo/shared/api";
import { UserContext } from "@hygo/shared/contexts";
import { Checkmark, SimpleArrowRight } from "@hygo/shared/icons";
import { ModalsContext } from "@hygo/shared/modals";
import { Feature, OAD as OADEnum, OADEvents, SnackbarType } from "@hygo/shared/models";
import { SnackbarContext } from "@hygo/shared/snackbar";
import { OADContext } from "@hygo/web/contexts";
import { useChargebee, useQuery } from "@hygo/web/hooks";
import { SmagLoginModal, SmagManagementModal } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import OADScreen from "./OADScreen";
import { OADScreenProps } from "./screen.types";

const OADContainer = (): JSX.Element => {
	const query = useQuery();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const hasMileos = useFeature(Feature.MILEOS);
	const [loading, setLoading] = useState<OADScreenProps["loading"]>(false);
	const { showModal } = useContext(ModalsContext);
	const { fetchUser, user } = useContext(UserContext);
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { showSnackbar } = useContext(SnackbarContext);
	const { deleteSmagToken, getSmagToken, linkToSmag, patchUser } = useApi();
	const { loggedInSmag } = useContext(OADContext);
	const methods = useForm({ mode: "all" });
	const { openChargebeePortal } = useChargebee();

	const sendHubspotNotification = useCallback(
		async (suggestion: string): Promise<void> => {
			try {
				const userOads = user?.qualification?.oads?.length > 0 ? user.qualification.oads : [];
				await patchUser({
					qualification: {
						area: null,
						crops: null,
						oads: [...userOads, suggestion as OADEnum],
						plans: user?.qualification?.plans || null
					}
				});
				await fetchUser();
				showSnackbar(t("snackbar.oadSuggestion.success"), SnackbarType.SUCCESS);
			} catch (e) {
				showSnackbar(t("snackbar.oadSuggestion.error"), SnackbarType.ERROR);
				throw e;
			}
		},
		[patchUser, showSnackbar, t, fetchUser, user]
	);

	const onSubmit: OADScreenProps["onSubmit"] = async ({ suggestion }) => {
		setLoading(true);
		try {
			await sendHubspotNotification(suggestion);
			methods.reset({ suggestion: null });
		} finally {
			setLoading(false);
		}
	};

	const isAlreadyRequested = useCallback(
		(oad: OADEnum): boolean => !!user?.qualification?.oads?.find((requestedOad) => requestedOad === oad),
		[user]
	);

	const OADList = useMemo(
		() =>
			Object.values(OADEnum).map((oad) => {
				switch (oad) {
					case OADEnum.SMAG:
						return {
							available: true,
							id: oad,
							isLoggedIn: loggedInSmag,
							primaryAction: {
								icon: SimpleArrowRight,
								onClick: async () => {
									logAnalyticEvent(OADEvents.clickLoginToSmag);
									await deleteSmagToken();
									await fetchUser();
									const loginUri = await getSmagToken();
									showModal(SmagLoginModal, { loginUri });
								}
							},
							secondaryAction: {
								icon: SimpleArrowRight,
								onClick: () => {
									showModal(SmagManagementModal, {
										onGoToImport: () => navigate("/import/smag")
									});
								}
							}
						};
					case OADEnum.GEOFOLIA:
						return {
							available: true,
							id: oad,
							isLoggedIn: true,
							primaryAction: {
								icon: SimpleArrowRight,
								onClick: sendHubspotNotification
							},
							secondaryAction: {
								icon: SimpleArrowRight,
								onClick: async (): Promise<void> => {
									navigate("/import/telepac");
								}
							}
						};
					case OADEnum.MILEOS:
						return {
							available: true,
							id: oad,
							isLoggedIn: !!hasMileos,
							primaryAction: {
								icon: SimpleArrowRight,
								onClick: openChargebeePortal
							},
							secondaryAction: {
								disabled: true,
								icon: Checkmark,
								onClick: () => null
							}
						};
					default:
						return {
							available: false,
							id: oad,
							primaryAction: {
								disabled: isAlreadyRequested(oad),
								icon: isAlreadyRequested(oad) ? Checkmark : SimpleArrowRight,
								onClick: sendHubspotNotification
							}
						};
				}
			}),
		[
			loggedInSmag,
			deleteSmagToken,
			fetchUser,
			isAlreadyRequested,
			hasMileos,
			openChargebeePortal,
			sendHubspotNotification,
			logAnalyticEvent,
			getSmagToken,
			navigate,
			showModal
		]
	);

	const availableOADList = OADList.filter((oad) => oad.available);
	const unavailableOADList = OADList.filter((oad) => !oad.available);

	useEffect(() => {
		const checkSmagAuth = async (): Promise<void> => {
			const code = query.get("code");
			if (!code) return;
			try {
				await linkToSmag({ code });
				await fetchUser();
				showSnackbar(t("snackbar.smagAuth.success"), SnackbarType.SUCCESS);
			} catch (e) {
				showSnackbar(t(`snackbar.smagAuth.${e?.response?.data?.code}`), SnackbarType.ERROR);
				throw e;
			}
		};
		checkSmagAuth();
	}, [linkToSmag, showSnackbar, t, query, fetchUser]);

	return (
		<OADScreen
			availableOADList={availableOADList}
			loading={loading}
			methods={methods}
			onSubmit={onSubmit}
			unavailableOADList={unavailableOADList}
		/>
	);
};

export default OADContainer;
