import { AmplitudeContext } from "@hygo/shared/amplitude";
import { availableLanguages, langueMapping } from "@hygo/shared/constants";
import { UserContext } from "@hygo/shared/contexts";
import { useAccountSettings } from "@hygo/shared/feature-account-settings";
import { ModalsContext } from "@hygo/shared/modals";
import { AccountEvents, Feature, UserLevel } from "@hygo/shared/models";
import { useChargebee } from "@hygo/web/hooks";
import { AccountDeleteModal, PasswordUpdateModal } from "@hygo/web/ui-components";
import { useFeature } from "flagged";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import AccountScreen from "./AccountScreen";
import { AccountScreenProps } from "./screen.types";

const AccountContainer = (): JSX.Element => {
	const { t } = useTranslation();
	const { logAnalyticEvent } = useContext(AmplitudeContext);
	const { planId, plans, user } = useContext(UserContext);
	const { showModal } = useContext(ModalsContext);
	const { openChargebeePortal } = useChargebee();
	const navigate = useNavigate();
	const hasMileos = useFeature(Feature.MILEOS);
	const hasPotatoesOnly = useFeature(Feature.POTATOES_ONLY);
	const hasFarmWeather = useFeature(Feature.FARM_WEATHER);

	const languages = availableLanguages?.map((l) => {
		return {
			Icon: langueMapping[l]({ height: 20, width: 20 }) as JSX.Element,
			label: t(`language.${l}`),
			value: l
		};
	});

	const { coops, loading, methods, onEditPhoneCountrySelector, rules, updateUser } = useAccountSettings();

	const onSubmit: AccountScreenProps["onSubmit"] = async (formValues) => await updateUser(formValues);

	const onClickPasswordModal: AccountScreenProps["onClickPasswordModal"] = () => {
		logAnalyticEvent(AccountEvents.editPassword);
		showModal(PasswordUpdateModal);
	};

	const onClickPricing: AccountScreenProps["onClickPricing"] = () => {
		logAnalyticEvent(AccountEvents.goToPricing);
		navigate("/pricing");
	};

	const onClickAddMorePlots: AccountScreenProps["onClickAddMorePlots"] = () => {
		logAnalyticEvent(AccountEvents.addMorePlots);
		navigate("/pricing");
	};

	const onCancel: AccountScreenProps["onCancel"] = () => methods.reset();

	const redirectToOAD: AccountScreenProps["redirectToOAD"] = () => navigate("/oad");

	const onClickManageSubscription: AccountScreenProps["onClickManageSubscription"] = () => {
		logAnalyticEvent(AccountEvents.clickManageSubscription);
		openChargebeePortal();
	};

	const plan = plans.find((p) => p.id === planId);

	const openAccountDeleteModal: AccountScreenProps["openAccountDeleteModal"] = () => {
		showModal(AccountDeleteModal);
	};

	return (
		<AccountScreen
			coops={coops}
			countryCode={user?.countryCode}
			hasFarmWeather={!!hasFarmWeather}
			hasMileosAddon={!!hasMileos && !hasPotatoesOnly}
			isFarmer={user?.level !== UserLevel.NO_FARMER}
			languages={languages}
			loading={loading}
			logAnalyticEvent={logAnalyticEvent}
			methods={methods}
			onCancel={onCancel}
			onClickAddMorePlots={onClickAddMorePlots}
			onClickManageSubscription={onClickManageSubscription}
			onClickPasswordModal={onClickPasswordModal}
			onClickPricing={onClickPricing}
			onEditPhoneCountrySelector={onEditPhoneCountrySelector}
			onSubmit={onSubmit}
			openAccountDeleteModal={openAccountDeleteModal}
			plan={plan}
			planStatus={user?.plan?.planStatus}
			redirectToOAD={redirectToOAD}
			rules={rules}
		/>
	);
};

export default AccountContainer;
