import { Trash } from "@hygo/shared/icons";
import { Crop, DenormalizedField, User } from "@hygo/shared/models";
import { COLORS } from "@hygo/shared/utils";
import styled from "styled-components";

import FieldsSection from "./FieldsSection";
import IrrigationSection from "./IrrigationSection";
import MapSection from "./MapSection";

interface AccordionDetailsProps {
	crops: Crop[];
	endTime: string;
	fields: DenormalizedField[];
	id: number;
	onDeleteIrrigation: (irrigationId: number) => void;
	quantity: number;
	selectedFields: DenormalizedField[];
	startTime: string;
	user: User;
}

const Wrapper = styled.div`
	margin: -16px;
	padding: 16px;
	background: var(--gradient-light-grey);
	position: relative;
`;

const DeleteButton = styled.button`
	position: absolute;
	top: 16px;
	right: 16px;
	cursor: pointer;
	background-color: transparent;
	border: none;
	padding: 0;
`;

const BottomSection = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 16px;
`;

const SubWrapper = styled.div`
	flex: 1;
`;

const AccordionDetails = ({
	crops,
	endTime,
	fields,
	id,
	onDeleteIrrigation,
	quantity,
	selectedFields,
	startTime,
	user
}: AccordionDetailsProps): JSX.Element => {
	return (
		<Wrapper>
			<DeleteButton onClick={() => onDeleteIrrigation(id)} type="button">
				<Trash fill={COLORS.GASPACHO[100]} height={32} width={32} />
			</DeleteButton>
			<FieldsSection crops={crops} fields={fields} selectedFields={selectedFields} />
			<BottomSection>
				<SubWrapper>
					<MapSection fields={fields} user={user} />
				</SubWrapper>
				<SubWrapper>
					<IrrigationSection
						countryCode={user?.countryCode}
						endTime={endTime}
						quantity={quantity}
						startTime={startTime}
					/>
				</SubWrapper>
			</BottomSection>
		</Wrapper>
	);
};

export default AccordionDetails;
