import { ArrowLeft } from "@hygo/shared/icons";
import { UserLevel } from "@hygo/shared/models";
import { CardWithImage, ContainerWithBgImage, FormCard, FormHeader, SimpleButton } from "@hygo/web/ui-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { SignupLandingScreenProps } from "./screen.types";

const Wrapper = styled(FormCard)`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;

const FlowWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 40px;
`;

const Link = styled(SimpleButton)`
	text-align: center;
	width: unset;
`;

const SignupLandingScreen = ({ goToAgriFlow, onGoBack }: SignupLandingScreenProps): JSX.Element => {
	const { t } = useTranslation();
	return (
		<ContainerWithBgImage backgroundImage={"/assets/images/bg.jpg"}>
			<Wrapper>
				<FormHeader
					backIcon={<ArrowLeft />}
					onGoBack={onGoBack}
					subTitle={t("screens.signUpLanding.subtitle")}
					title={t("screens.signUpLanding.title")}
				/>
				<FlowWrapper>
					<CardWithImage
						asset={{ image: "/assets/images/agriculteur.png" }}
						imageWidth={165}
						onClick={() => goToAgriFlow(UserLevel.FARMER)}
						title={t("screens.signUpLanding.farmer.title")}
					/>
					<CardWithImage
						asset={{ image: "/assets/images/coop.png" }}
						disabled
						imageWidth={100}
						subTitle={t("screens.signUpLanding.coop.subtitle")}
						title={t("screens.signUpLanding.coop.title")}
					/>
				</FlowWrapper>
				<Link
					onClick={() => goToAgriFlow(UserLevel.NO_FARMER)}
					text={t("screens.signUpLanding.notFarmer.title")}
				/>
			</Wrapper>
		</ContainerWithBgImage>
	);
};

export default SignupLandingScreen;
