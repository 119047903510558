import {
	ArvalisDevice,
	BaseArvalisDevice,
	BaseFarm,
	BaseIrrigation,
	BaseMileosFarmAdvisor,
	BaseMileosRainFall,
	BaseMixture,
	BaseNozzle,
	BaseSprayer,
	Coop,
	CoopUser,
	Crop,
	DetectedTask,
	EDIField,
	EditTask,
	Farm,
	Field,
	Hardness,
	IntercomTemplate,
	Irrigation,
	Language,
	MileosAdvisor,
	MileosFarmAdvisor,
	MileosField,
	MileosIndices,
	MileosInfos,
	MileosRainFall,
	MileosStatus,
	MileosVariety,
	Mixture,
	Modulation,
	NewUser,
	Notification,
	NotificationAction,
	Nozzle,
	OAD,
	PartnerMeasure,
	Plan,
	PlanName,
	PositionRealTime,
	Product,
	ProductConstants,
	ProductUsage,
	Radar,
	RPGField,
	SmagFarm,
	SmagField,
	Soil,
	Sprayer,
	Tank,
	Target,
	Task,
	User,
	Weather,
	WeatherRealTime,
	WindSpeedUnity
} from "@hygo/shared/models";
import {
	addDays,
	differenceBetweenDates,
	formatDateToLocale,
	getStartOfDayAsJSDate,
	getXNextDays,
	isBetweenDates,
	substractDays,
	substractHours
} from "@hygo/shared/utils";
import { Method } from "axios";
import { useCallback, useContext, useRef } from "react";
import { Region } from "react-native-maps";

import { AxiosContext } from "./AxiosContext";

interface useApiResult {
	cancel: () => void;
	checkEmailAndSendPasswordReset: (email: string) => Promise<void>;
	checkSetup: () => Promise<string>;
	checkTank: (data: { productIds: string[]; targetIds: number[] }) => Promise<Tank>;
	checkToken: (data: { email: string; password: string }) => Promise<string>;
	createArvalisDevice: (device: BaseArvalisDevice) => Promise<{ id: number }>;
	createComingTask: (data: { farmId: number; task: EditTask }) => Promise<{ id: number }>;
	createDoneTask: (data: { farmId: number; task: EditTask }) => Promise<{ id: number }>;
	createFarm: (farm: BaseFarm) => Promise<{ id: number }>;
	createField: (data: {
		coordinates: { lat: number; lon: number }[];
		cropId: number;
		farmId: number;
		harvestDate: Date;
		name: string;
		sowingDate: Date;
	}) => Promise<{ id: number }>;
	createIrrigation: (data: { farmId: number; irrigation: BaseIrrigation }) => Promise<{ id: number }>;
	createMixture: (data: { farmId: number; mixture: BaseMixture }) => Promise<{ id: number }>;
	createNozzle: (nozzle: BaseNozzle) => Promise<{ id: number }>;
	createPushToken: (token: string) => Promise<{ id: number }>;
	createSprayer: (sprayer: BaseSprayer) => Promise<{ id: number }>;
	createUser: (newUser: NewUser) => Promise<{ id: number }>;
	deleteArvalisDevice: (deviceId: number) => Promise<void>;
	deleteComingTask: (data: { farmId: number; taskId: number }) => Promise<void>;
	deleteDetectedTask: (data: { farmId: number; taskId: number }) => Promise<void>;
	deleteDoneTask: (data: { farmId: number; taskId: number }) => Promise<void>;
	deleteFarm: (farmId: number) => Promise<void>;
	deleteFarmMileosAdvisor: (data: { advisorId: number; farmId: number }) => Promise<void>;
	deleteFields: (data: { farmId: number; fieldIds: number[] }) => Promise<void>;
	deleteIrrigation: (data: { farmId: number; irrigationId: number }) => Promise<void>;
	deleteMixture: (data: { farmId: number; mixtureId: number }) => Promise<void>;
	deleteNoticeFile: (noticeKey: string) => Promise<void>;
	deleteNotification: (notificationId: number) => Promise<void>;
	deleteNozzle: (data: { nozzleId: number; sprayerId: number }) => Promise<void>;
	deletePushToken: (token: string) => Promise<void>;
	deleteSmagToken: () => Promise<void>;
	deleteSprayer: (sprayerId: number) => Promise<void>;
	deleteUser: () => Promise<void>;
	exportMileosCampaign: (farmId: number) => Promise<void>;
	exportSmagTasks: (data: {
		farmId: number;
		taskIds: number[];
	}) => Promise<{ failedExports: number; succeededExports: number }>;
	exportTasks: (data: {
		farmId: number;
		fieldIds: number[];
		format: string;
		startAfter: Date;
		startBefore: Date;
		taskIds: number[];
	}) => Promise<string>;
	getAbout: () => Promise<{ build: number; maintenance: boolean }>;
	getAdminToken: (userId: number) => Promise<string>;
	getArvalisDevices: () => Promise<ArvalisDevice[]>;
	getArvalisDevicesConstructors: () => Promise<string[]>;
	getChargebeePortalSession: () => Promise<void>;
	getComingTask: (data: { farmId: number; taskId: number }) => Promise<Task>;
	getComingTasks: (farmId: number) => Promise<Task[]>;
	getCoops: () => Promise<Coop[]>;
	getCoopUsers: () => Promise<CoopUser[]>;
	getCrops: () => Promise<Crop[]>;
	getDetectedTasks: (data: { farmId: number; startAfter?: Date; startBefore?: Date }) => Promise<DetectedTask[]>;
	getDoneTask: (data: { farmId: number; taskId: number }) => Promise<Task>;
	getDoneTasks: (data: { farmId: number; startAfter?: Date; startBefore: Date }) => Promise<Task[]>;
	getEdiFields: (data: { fileId: string; geofolia: boolean }) => Promise<EDIField[]>;
	getFarmMileosAdvisors: (farmId: number) => Promise<MileosFarmAdvisor[]>;
	getFarms: () => Promise<Farm[]>;
	getFarmWeather: (data: { farmId: number; startAt: Date }) => Promise<Weather>;
	getFields: (data: { farmId: number; onlyChecked: boolean; productIds?: string[] }) => Promise<Field[]>;
	getIrrigation: (data: { farmId: number; irrigationId: number }) => Promise<Irrigation>;
	getIrrigations: (data: { farmId: number; startAfter?: Date; startBefore: Date }) => Promise<Irrigation[]>;
	getLocaleWeather: (startAt: Date) => Promise<Weather>;
	getMileosAdvisors: () => Promise<MileosAdvisor[]>;
	getMileosIndicesByFarm: (data: {
		endAt: Date;
		farmId: number;
		mileosCampaignEnd: Date;
		mileosCampaignStart: Date;
		startAt: Date;
	}) => Promise<MileosIndices>;
	getMileosIndicesByField: (data: {
		endAt: Date;
		farmId: number;
		fieldId: number;
		mileosCampaignEnd: Date;
		mileosCampaignStart: Date;
		startAt: Date;
	}) => Promise<MileosField>;
	getMileosInfos: (data: { farmId: number; fieldId: number }) => Promise<MileosInfos>;
	getMileosRainFall: (data: { farmId: number; fieldId: number }) => Promise<MileosRainFall[]>;
	getMileosVarieties: () => Promise<MileosVariety[]>;
	getMixture: (data: { farmId: number; mixtureId: number }) => Promise<Mixture>;
	getMixtures: (d: { farmId: number; page: number }) => Promise<{ count: number; list: Mixture[] }>;
	getModulation: (data: {
		delayInHours: number;
		farmId: number;
		fieldIds: number[];
		nozzleId: number;
		products: {
			activeModulation: boolean;
			productId: string;
			quantityPerHA: number;
			totalQuantity: number;
		}[];
		targetIds: number[];
		totalArea: number;
		varietyIds?: number[];
	}) => Promise<Modulation>;
	getNetatmoStationsAround: (region: Region) => Promise<{
		region: {
			latNE: number;
			latSW: number;
			lonNE: number;
			lonSW: number;
		};
		stationMeasures: PartnerMeasure[];
	}>;
	getNotification: (id: number) => Promise<Notification>;
	getNotifications: () => Promise<Notification[]>;
	getNozzles: () => Promise<Nozzle[]>;
	getPlans: () => Promise<Plan[]>;
	getProductConstants: () => Promise<ProductConstants>;
	getProductDosesUsages: (data: { farmId: number; productIds: string[] }) => Promise<ProductUsage[]>;
	getProducts: () => Promise<Product[]>;
	getRadar: () => Promise<Radar>;
	getRealtimePosition: (sprayerId: number) => Promise<PositionRealTime>;
	getRealtimeWeather: (sprayerId: number) => Promise<WeatherRealTime[]>;
	getRPGFields: (d: { latitude: number; longitude: number }) => Promise<RPGField[]>;
	getSmagFarms: (year: number, farmNameFilter?: string) => Promise<SmagFarm[]>;
	getSmagFields: (data: { cropYear: number; farmUid: string }) => Promise<SmagField[]>;
	getSmagMobileToken: () => Promise<string>;
	getSmagToken: () => Promise<string>;
	getSprayers: () => Promise<Sprayer[]>;
	getTargets: () => Promise<Target[]>;
	getUser: () => Promise<User>;
	importEdiFields: (data: {
		cropSynchronize: boolean;
		farmId: number;
		fields: { externalRef: string; fieldId: number }[];
		fileId: string;
		geofolia: boolean;
	}) => Promise<{
		createdFieldIds: number[];
		deletedFieldIds: number[];
		updatedFieldIds: number[];
	}>;
	importSmagFields: (data: {
		cropSynchronize: boolean;
		cropYear: number;
		farmId: number;
		fields: { fieldId: number; smagCropZoneUid: string }[];
		smagFarmUid: string;
	}) => Promise<{ createdFieldIds: number[]; deletedFieldIds: number[]; updatedFieldIds: number[] }>;
	linkToSmag: (data: { code: string }) => Promise<void>;
	markComingTaskAsDone: (data: {
		endTime: Date;
		farmId: number;
		startTime: Date;
		taskId: number;
	}) => Promise<{ id: number }>;
	markDetectedTaskAsUsed: (data: { detectedTaskId: number; farmId: number; task: EditTask }) => Promise<void>;
	markDetectedTasksAsRead: (data: { farmId: number; taskIds: number[] }) => Promise<void>;
	patchFarmMileosAdvisors: (data: { advisor: BaseMileosFarmAdvisor; farmId: number }) => Promise<void>;
	patchFields: (data: {
		farmId: number;
		fields: {
			area?: number;
			coordinates?: {
				lat: number;
				lon: number;
			}[];
			cropId?: number;
			harvestDate?: Date;
			id: number;
			name?: string;
			sowingDate?: Date;
			varietyId?: number;
		}[];
	}) => Promise<{ createdFieldIds: number[]; deletedFieldIds: number[]; weatherUpdatedFieldIds: number[] }>;
	patchMileosField: (data: {
		farmId: number;
		fieldId: number;
		mileosInfos: {
			deviceId?: number;
			observations?: string[];
			phases?: {
				date: string;
				name: string;
			}[];
			varietyId?: number;
			yieldValue?: number;
		};
	}) => Promise<void>;
	patchMileosRainFall: (data: { farmId: number; fieldId: number; rainFall: BaseMileosRainFall }) => Promise<void>;
	patchSprayerDefaultNozzleId: (data: { defaultNozzleId: number; sprayerId: number }) => Promise<void>;
	patchUser: (data: {
		additionalPhones?: string[];
		company?: string;
		coopId?: number;
		debit?: number;
		defaultFarmId?: number;
		defaultSprayerId?: number;
		firstName?: string;
		hveMode?: boolean;
		language?: Language;
		lastName?: string;
		location?: { label: string; lat: number; lon: number };
		notifSmsActivated?: boolean;
		password?: { newPassword: string; oldPassword: string };
		premiumRequestDate?: Date;
		principalPhone?: string;
		qualification?: {
			area: unknown;
			crops: unknown;
			oads: OAD[];
			plans: PlanName[];
		};
		soil?: Soil;
		soilAcidity?: number;
		tester?: boolean;
		waterAcidity?: number;
		waterHardness?: Hardness;
		waterTankBottomPercentage?: number;
		windSpeedUnity?: WindSpeedUnity;
	}) => Promise<void>;
	productAdministrationRequests: (
		data: {
			callbackError: (e: Error) => void;
			data: object;
			method: Method;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			params: any;
			translationKey: string;
			url: string;
		},
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		HttpError: any
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	) => Promise<any>;
	resetPassword: (data: { password: string; resetToken: string }) => Promise<void>;
	sendFakeMileosTreatment: (data: { farmId: number; fieldId: number }) => Promise<void>;
	sendProductMissingTicket: (productName: string) => Promise<void>;
	updateComingTask: (data: { farmId: number; task: EditTask; taskId: number }) => Promise<void>;
	updateDoneTask: (data: { farmId: number; task: EditTask; taskId: number }) => Promise<void>;
	updateFarm: (farm: Farm) => Promise<void>;
	updateIrrigation: (data: { farmId: number; irrigation: BaseIrrigation; irrigationId: number }) => Promise<void>;
	updateMaterializedView: () => Promise<void>;
	updateMixture: (data: {
		farmId: number;
		mixture: BaseMixture;
		mixtureId: number;
		taskIds?: number[];
	}) => Promise<void>;
	updateNotification: (data: { action: NotificationAction; notificationIds: number[] }) => Promise<void>;
	updateNozzle: (nozzle: Nozzle) => Promise<void>;
	updateSprayer: (sprayer: Sprayer) => Promise<void>;
	uploadEdiFile: (file: File) => Promise<string>;
	uploadNoticeFile: (data: { ammId: number; file: File; ucName: string }) => Promise<string>;
}

export const useApi = (): useApiResult => {
	const { apiRef } = useContext(AxiosContext);

	const controllerRef = useRef(new AbortController());
	const cancel: useApiResult["cancel"] = () => {
		controllerRef.current.abort();
	};

	const checkToken: useApiResult["checkToken"] = useCallback(
		async ({ email, password }) => {
			const response = await apiRef.post("/auth/tokens", {
				email,
				password
			});

			return response?.data?.token;
		},
		[apiRef]
	);

	const patchUser: useApiResult["patchUser"] = useCallback(
		async ({ password, ...data }): Promise<void> => {
			await apiRef.patch("/me", { ...data, ...password });
		},
		[apiRef]
	);

	const getCrops: useApiResult["getCrops"] = useCallback(async () => {
		const response = await apiRef.get("/agronomy/crops");
		return response?.data;
	}, [apiRef]);

	const getProductDosesUsages: useApiResult["getProductDosesUsages"] = useCallback(
		async ({ farmId, productIds }) => {
			const response = await apiRef.post(`/agronomy/farms/${farmId}/productsUsages`, { productIds });
			return response?.data;
		},
		[apiRef]
	);

	const getFields: useApiResult["getFields"] = useCallback(
		async ({ farmId, onlyChecked, productIds }) => {
			const response = await apiRef.post(`/farms/${farmId}/fields/search`, {
				onlyChecked,
				productIds
			});

			return response?.data;
		},
		[apiRef]
	);

	const getMileosIndicesByFarm: useApiResult["getMileosIndicesByFarm"] = useCallback(
		async ({ endAt: endAtParam, farmId, mileosCampaignEnd, mileosCampaignStart, startAt: startAtParam }) => {
			const endAt = isBetweenDates({
				date: endAtParam,
				endAt: mileosCampaignEnd,
				startAt: mileosCampaignStart
			})
				? endAtParam
				: mileosCampaignEnd;
			const startAt = isBetweenDates({
				date: startAtParam,
				endAt: mileosCampaignEnd,
				startAt: mileosCampaignStart
			})
				? startAtParam
				: mileosCampaignStart;
			const numberOfDays = differenceBetweenDates(endAt, startAt) + 1;

			const days = getXNextDays(numberOfDays, startAt).map((d) => new Date(d));
			const daysMapping = days.flatMap((day) => {
				const startOfDay = getStartOfDayAsJSDate(new Date()).getTime();
				let origin;
				if (day.getTime() === startOfDay) origin = ["PASSED", "ESTIMATED"];
				else origin = day.getTime() > startOfDay ? ["ESTIMATED"] : ["PASSED"];
				return origin.map((o) => {
					return {
						date: formatDateToLocale(day, "fr-CA"),
						fieldStatus: MileosStatus.B_NO_STATUS,
						origin: o
					};
				});
			});

			const response = await apiRef.get(`/farms/${farmId}/mileosIndices`, {
				params: {
					endAt: formatDateToLocale(endAt, "fr-CA"),
					startAt: formatDateToLocale(startAt, "fr-CA"),
					today: formatDateToLocale(getStartOfDayAsJSDate(new Date()), "fr-CA")
				}
			});

			return {
				farmIndices: response?.data?.farmIndices?.map((f: MileosField) => {
					const dailyFieldIndices = daysMapping.map(
						(mapObject) =>
							f.dailyFieldIndices.find(
								(i) => i.date === mapObject.date && i.origin === mapObject.origin
							) || mapObject
					);
					return {
						...f,
						dailyFieldIndices
					};
				})
			};
		},
		[apiRef]
	);

	const getMileosIndicesByField: useApiResult["getMileosIndicesByField"] = useCallback(
		async ({
			endAt: endAtParam,
			farmId,
			fieldId,
			mileosCampaignEnd,
			mileosCampaignStart,
			startAt: startAtParam
		}) => {
			const endAt = isBetweenDates({
				date: endAtParam,
				endAt: mileosCampaignEnd,
				startAt: mileosCampaignStart
			})
				? endAtParam
				: mileosCampaignEnd;

			const startAt = isBetweenDates({
				date: startAtParam,
				endAt: mileosCampaignEnd,
				startAt: mileosCampaignStart
			})
				? startAtParam
				: mileosCampaignStart;

			const response = await apiRef.get(`/farms/${farmId}/fields/${fieldId}/mileosIndices`, {
				params: {
					endAt: formatDateToLocale(endAt, "fr-CA"),
					startAt: formatDateToLocale(startAt, "fr-CA"),
					today: formatDateToLocale(getStartOfDayAsJSDate(new Date()), "fr-CA")
				}
			});

			return response?.data;
		},
		[apiRef]
	);

	const getCoopUsers: useApiResult["getCoopUsers"] = useCallback(async () => {
		const response = await apiRef.get("/administration/users");
		return response?.data;
	}, [apiRef]);

	const getMileosAdvisors: useApiResult["getMileosAdvisors"] = useCallback(async () => {
		const response = await apiRef.get("/partners/mileos/advisors");
		return response?.data;
	}, [apiRef]);

	const getFarmMileosAdvisors: useApiResult["getFarmMileosAdvisors"] = useCallback(
		async (farmId) => {
			const response = await apiRef.get(`/farms/${farmId}/mileosAdvisors`);
			return response?.data;
		},
		[apiRef]
	);

	const patchFarmMileosAdvisors: useApiResult["patchFarmMileosAdvisors"] = useCallback(
		async ({ advisor: { fieldIds, id }, farmId }) => {
			await apiRef.patch(`/farms/${farmId}/mileosAdvisors/${id}`, { fieldIds });
		},
		[apiRef]
	);

	const deleteFarmMileosAdvisor: useApiResult["deleteFarmMileosAdvisor"] = useCallback(
		async ({ advisorId, farmId }) => {
			await apiRef.delete(`/farms/${farmId}/mileosAdvisors/${advisorId}`);
		},
		[apiRef]
	);

	const getAbout: useApiResult["getAbout"] = useCallback(async () => {
		const response = await apiRef.get("/auth/about");
		return response?.data;
	}, [apiRef]);

	const getProductConstants: useApiResult["getProductConstants"] = useCallback(async () => {
		const response = await apiRef.get("/administration/products/constants");
		return response?.data;
	}, [apiRef]);

	const getRPGFields: useApiResult["getRPGFields"] = useCallback(
		async ({ latitude, longitude }) => {
			const response = await apiRef.get("/partners/rpg/cropZones", { params: { lat: latitude, lon: longitude } });
			return response?.data;
		},
		[apiRef]
	);

	const patchFields: useApiResult["patchFields"] = useCallback(
		async ({ farmId, fields }) => {
			const formattedFields = fields?.map((f) => ({
				...f,
				harvestDate: f?.harvestDate ? formatDateToLocale(new Date(f?.harvestDate), "fr-CA") : undefined,
				sowingDate: f?.sowingDate ? formatDateToLocale(new Date(f?.sowingDate), "fr-CA") : undefined
			}));
			const response = await apiRef.patch(`/farms/${farmId}/fields`, formattedFields);
			return response?.data;
		},
		[apiRef]
	);

	const getNetatmoStationsAround: useApiResult["getNetatmoStationsAround"] = useCallback(
		async (region) => {
			const latNE = region.latitude + region.latitudeDelta / 2;
			const lonNE = region.longitude + region.longitudeDelta / 2;
			const latSW = region.latitude - region.latitudeDelta / 2;
			const lonSW = region.longitude - region.longitudeDelta / 2;

			const response = await apiRef.post("/measures/partnerLocalWeather", { latNE, latSW, lonNE, lonSW });

			return response?.data;
		},
		[apiRef]
	);

	const createUser: useApiResult["createUser"] = useCallback(
		async (newUser) => {
			const response = await apiRef.post("/auth/users", newUser);
			return response?.data;
		},
		[apiRef]
	);

	const createField: useApiResult["createField"] = useCallback(
		async ({ coordinates, cropId, farmId, harvestDate, name, sowingDate }) => {
			const response = await apiRef.post(`/farms/${farmId}/fields`, {
				coordinates,
				cropId,
				harvestDate: harvestDate ? formatDateToLocale(new Date(harvestDate), "fr-CA") : undefined,
				name,
				sowingDate: sowingDate ? formatDateToLocale(new Date(sowingDate), "fr-CA") : undefined
			});
			return response?.data;
		},
		[apiRef]
	);

	const updateMaterializedView: useApiResult["updateMaterializedView"] = useCallback(async () => {
		await apiRef.request({
			method: "POST",
			timeout: 120000,
			url: "/administration/updateMaterializedView"
		});
	}, [apiRef]);

	const createArvalisDevice: useApiResult["createArvalisDevice"] = useCallback(
		async (device) => {
			const response = await apiRef.post(`/equipments/arvalisDevices`, device);
			return response?.data;
		},
		[apiRef]
	);

	const deleteArvalisDevice: useApiResult["deleteArvalisDevice"] = useCallback(
		async (deviceId) => {
			await apiRef.delete(`/equipments/arvalisDevices/${deviceId}`);
		},
		[apiRef]
	);

	const getCoops: useApiResult["getCoops"] = useCallback(async () => {
		const response = await apiRef.get("/auth/coops");
		return response?.data;
	}, [apiRef]);

	const checkTank: useApiResult["checkTank"] = useCallback(
		async ({ productIds, targetIds }) => {
			const response = await apiRef.post("/agronomy/checkTank", { productIds, targetIds: targetIds || [] });
			return response?.data;
		},
		[apiRef]
	);
	const sendProductMissingTicket: useApiResult["sendProductMissingTicket"] = useCallback(
		async (productName) => {
			await apiRef.post("/partners/intercom/tickets", {
				ticketContent: {
					productName
				},
				ticketType: IntercomTemplate.MISSING_PRODUCT
			});
		},
		[apiRef]
	);
	const updateNotification: useApiResult["updateNotification"] = useCallback(
		async ({ action, notificationIds }) => {
			await apiRef.post("/pushEvents/notifications/actions", { action, notificationIds });
		},
		[apiRef]
	);

	const getNotifications: useApiResult["getNotifications"] = useCallback(async () => {
		const response = await apiRef.get("/pushEvents/notifications");
		return response?.data?.map((notif: Notification) => {
			return { ...notif, date: new Date(notif?.date) };
		});
	}, [apiRef]);

	const sendFakeMileosTreatment: useApiResult["sendFakeMileosTreatment"] = useCallback(
		async ({ farmId, fieldId }) => {
			await apiRef.post(`farms/${farmId}/fields/${fieldId}/mileosFakeTreatment`);
		},
		[apiRef]
	);

	const getNotification: useApiResult["getNotification"] = useCallback(
		async (id) => {
			const response = await apiRef.get(`/pushEvents/notifications/${id}`);
			return response?.data;
		},
		[apiRef]
	);

	const checkEmailAndSendPasswordReset: useApiResult["checkEmailAndSendPasswordReset"] = useCallback(
		async (email) => {
			await apiRef.post("/auth/forgotPassword", {
				email
			});
		},
		[apiRef]
	);

	const resetPassword: useApiResult["resetPassword"] = useCallback(
		async ({ password, resetToken }) => {
			await apiRef.post("/auth/resetPassword", { password, token: resetToken });
		},
		[apiRef]
	);

	const getProducts: useApiResult["getProducts"] = useCallback(async () => {
		const response = await apiRef.get("/agronomy/products");
		return response?.data;
	}, [apiRef]);

	const getPlans: useApiResult["getPlans"] = useCallback(async () => {
		const response = await apiRef.get("/pricing/chargebee/plans");
		return response?.data;
	}, [apiRef]);

	const getFarms: useApiResult["getFarms"] = useCallback(async () => {
		const response = await apiRef.get("/farms");

		return response?.data;
	}, [apiRef]);

	const createFarm: useApiResult["createFarm"] = useCallback(
		async (farm) => {
			const response = await apiRef.post("/farms", farm);
			return response?.data;
		},
		[apiRef]
	);

	const updateFarm: useApiResult["updateFarm"] = useCallback(
		async ({ id, name }) => {
			await apiRef.put(`/farms/${id}`, { name });
		},
		[apiRef]
	);

	const deleteFarm: useApiResult["deleteFarm"] = useCallback(
		async (farmId) => {
			await apiRef.delete(`/farms/${farmId}`);
		},
		[apiRef]
	);

	const getUser: useApiResult["getUser"] = useCallback(async () => {
		const response = await apiRef.get("/me");
		const user = response?.data;
		return {
			...user,
			setup: {
				...user?.setup,
				mileosCampaignEnd: user?.setup?.mileosCampaignEnd
					? getStartOfDayAsJSDate(user?.setup?.mileosCampaignEnd)
					: null,
				mileosCampaignStart: user?.setup?.mileosCampaignStart
					? getStartOfDayAsJSDate(user?.setup?.mileosCampaignStart)
					: null
			}
		};
	}, [apiRef]);

	const deleteFields: useApiResult["deleteFields"] = useCallback(
		async ({ farmId, fieldIds }) => {
			await apiRef.post(`/farms/${farmId}/fields/deleteFields`, {
				fieldIds
			});
		},
		[apiRef]
	);

	const deletePushToken: useApiResult["deletePushToken"] = useCallback(
		async (token) => {
			await apiRef.delete("/pushEvents/pushTokens", { data: { pushToken: token } });
		},
		[apiRef]
	);

	const createPushToken: useApiResult["createPushToken"] = useCallback(
		async (token) => {
			const response = await apiRef.post("/pushEvents/pushTokens", {
				pushToken: token
			});
			return response?.data;
		},
		[apiRef]
	);

	const getFarmWeather: useApiResult["getFarmWeather"] = useCallback(
		async ({ farmId, startAt }) => {
			const response = await apiRef.post("/measures/farmWeather", { farmId, startAt });
			return response?.data;
		},
		[apiRef]
	);

	const updateNozzle: useApiResult["updateNozzle"] = useCallback(
		async ({ deleted, id, sprayerId, ...baseNozzle }) => {
			await apiRef.put(`/equipments/sprayers/${sprayerId}/nozzles/${id}`, baseNozzle);
		},
		[apiRef]
	);

	const deleteNozzle: useApiResult["deleteNozzle"] = useCallback(
		async ({ nozzleId, sprayerId }) => {
			await apiRef.delete(`/equipments/sprayers/${sprayerId}/nozzles/${nozzleId}`);
		},
		[apiRef]
	);

	const createNozzle: useApiResult["createNozzle"] = useCallback(
		async ({ sprayerId, ...rest }) => {
			const response = await apiRef.post(`/equipments/sprayers/${sprayerId}/nozzles`, rest);
			return response?.data;
		},
		[apiRef]
	);

	const getNozzles: useApiResult["getNozzles"] = useCallback(async () => {
		const response = await apiRef.get("/equipments/nozzles");
		return response?.data;
	}, [apiRef]);

	const getArvalisDevices: useApiResult["getArvalisDevices"] = useCallback(async () => {
		const response = await apiRef.get("/equipments/arvalisDevices");
		return response?.data;
	}, [apiRef]);

	const getArvalisDevicesConstructors: useApiResult["getArvalisDevicesConstructors"] = useCallback(async () => {
		const response = await apiRef.get("/equipments/arvalisDevices/constructors");
		return response?.data;
	}, [apiRef]);

	const getAdminToken: useApiResult["getAdminToken"] = useCallback(
		async (userId) => {
			const response = await apiRef.get(`/administration/users/${userId}/token`);
			return response?.data?.token;
		},
		[apiRef]
	);

	const getTargets: useApiResult["getTargets"] = useCallback(async () => {
		const response = await apiRef.get("/agronomy/targets");
		return response?.data;
	}, [apiRef]);

	const getSprayers: useApiResult["getSprayers"] = useCallback(async () => {
		const response = await apiRef.get("equipments/sprayers");
		return response?.data;
	}, [apiRef]);

	const deleteNotification: useApiResult["deleteNotification"] = useCallback(
		async (id) => {
			await apiRef.delete(`/pushEvents/notifications/${id}`);
		},
		[apiRef]
	);

	const updateSprayer: useApiResult["updateSprayer"] = useCallback(
		async ({ deleted, gpsBarcode, id, weatherBarcode, ...rest }) => {
			await apiRef.put(`equipments/sprayers/${id}`, {
				...rest,
				gpsBarcode: gpsBarcode || null,
				weatherBarcode: weatherBarcode || null
			});
		},
		[apiRef]
	);

	const patchSprayerDefaultNozzleId: useApiResult["patchSprayerDefaultNozzleId"] = useCallback(
		async ({ defaultNozzleId, sprayerId }) => {
			await apiRef.patch(`equipments/sprayers/${sprayerId}`, {
				defaultNozzleId
			});
		},
		[apiRef]
	);

	const createSprayer: useApiResult["createSprayer"] = useCallback(
		async ({ gpsBarcode, weatherBarcode, ...rest }) => {
			const response = await apiRef.post("equipments/sprayers", {
				...rest,
				gpsBarcode: gpsBarcode || null,
				weatherBarcode: weatherBarcode || null
			});
			return response?.data;
		},
		[apiRef]
	);

	const deleteSprayer: useApiResult["deleteSprayer"] = useCallback(
		async (sprayerId) => {
			await apiRef.delete(`equipments/sprayers/${sprayerId}`);
		},
		[apiRef]
	);

	const exportMileosCampaign: useApiResult["exportMileosCampaign"] = useCallback(
		async (farmId) => {
			await apiRef.post(`farms/${farmId}/exportMileosCampaign`);
		},
		[apiRef]
	);

	const getChargebeePortalSession: useApiResult["getChargebeePortalSession"] = useCallback(async () => {
		const response = await apiRef.post("/pricing/chargebee/portalSession");
		return response?.data;
	}, [apiRef]);

	const createDoneTask: useApiResult["createDoneTask"] = useCallback(
		async ({ farmId, task }) => {
			const response = await apiRef.post(`userWorks/farms/${farmId}/doneTasks`, task);
			return response?.data;
		},
		[apiRef]
	);

	const updateComingTask: useApiResult["updateComingTask"] = useCallback(
		async ({ farmId, task, taskId }) => {
			await apiRef.put(`userWorks/farms/${farmId}/comingTasks/${taskId}`, task);
		},
		[apiRef]
	);

	const getComingTask: useApiResult["getComingTask"] = useCallback(
		async ({ farmId, taskId }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/comingTasks/${taskId}`);
			return response?.data;
		},
		[apiRef]
	);

	const updateIrrigation: useApiResult["updateIrrigation"] = useCallback(
		async ({ farmId, irrigation, irrigationId }) => {
			const response = await apiRef.put(`userWorks/farms/${farmId}/irrigations/${irrigationId}`, irrigation);
			return response?.data;
		},
		[apiRef]
	);

	const deleteIrrigation: useApiResult["deleteIrrigation"] = useCallback(
		async ({ farmId, irrigationId }) => {
			await apiRef.delete(`userWorks/farms/${farmId}/irrigations/${irrigationId}`);
		},
		[apiRef]
	);

	const createIrrigation: useApiResult["createIrrigation"] = useCallback(
		async ({ farmId, irrigation }) => {
			const response = await apiRef.post(`userWorks/farms/${farmId}/irrigations`, irrigation);
			return response?.data;
		},
		[apiRef]
	);
	const getIrrigations: useApiResult["getIrrigations"] = useCallback(
		async ({ farmId, startAfter, startBefore }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/irrigations`, {
				params: { startAfter, startBefore }
			});
			return response?.data;
		},
		[apiRef]
	);

	const getIrrigation: useApiResult["getIrrigation"] = useCallback(
		async ({ farmId, irrigationId }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/irrigations/${irrigationId}`);
			return response?.data;
		},
		[apiRef]
	);

	const getComingTasks: useApiResult["getComingTasks"] = useCallback(
		async (farmId) => {
			const startAfter = substractDays(getStartOfDayAsJSDate(new Date()), 7);
			const response = await apiRef.get(`userWorks/farms/${farmId}/comingTasks`, {
				params: { startAfter },
				signal: controllerRef?.current?.signal
			});
			return response?.data;
		},
		[apiRef]
	);

	const getRadar: useApiResult["getRadar"] = useCallback(async () => {
		const response = await apiRef.get("/measures/radar");
		return response?.data;
	}, [apiRef]);

	const createComingTask: useApiResult["createComingTask"] = useCallback(
		async ({ farmId, task }) => {
			const response = await apiRef.post(`userWorks/farms/${farmId}/comingTasks`, task);
			return response?.data;
		},
		[apiRef]
	);

	const checkSetup: useApiResult["checkSetup"] = useCallback(async () => {
		const response = await apiRef.post("/me/checkSetup");
		return response?.data;
	}, [apiRef]);

	const getModulation: useApiResult["getModulation"] = useCallback(
		async ({ delayInHours, farmId, fieldIds, nozzleId, products, targetIds, totalArea }) => {
			const startAt = getStartOfDayAsJSDate(new Date());
			const endAt = substractHours(addDays(startAt, 5), 1);
			const response = await apiRef.post("/agronomy/modulation", {
				delayInHours,
				endAt,
				farmId,
				fieldIds,
				nozzleId,
				products: products.map(({ activeModulation, productId, quantityPerHA, totalQuantity }) => ({
					activeModulation,
					productId,
					quantityPerHA,
					totalQuantity
				})),
				startAt,
				targetIds,
				totalArea
			});
			return response?.data;
		},
		[apiRef]
	);

	const getLocaleWeather: useApiResult["getLocaleWeather"] = useCallback(
		async (startAt) => {
			const response = await apiRef.post("/measures/localWeather", { startAt });

			return response?.data;
		},
		[apiRef]
	);

	const getRealtimePosition: useApiResult["getRealtimePosition"] = useCallback(
		async (sprayerId) => {
			const response = await apiRef.get(`/equipments/sprayers/${sprayerId}/positionRealtime`);
			return response?.data;
		},
		[apiRef]
	);

	const getRealtimeWeather: useApiResult["getRealtimeWeather"] = useCallback(
		async (sprayerId) => {
			const response = await apiRef.get(`/equipments/sprayers/${sprayerId}/weatherRealtime`);
			return response?.data;
		},
		[apiRef]
	);

	const getDoneTasks: useApiResult["getDoneTasks"] = useCallback(
		async ({ farmId, startAfter, startBefore }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/doneTasks`, {
				params: { startAfter, startBefore }
			});
			return response?.data;
		},
		[apiRef]
	);

	const getDetectedTasks: useApiResult["getDetectedTasks"] = useCallback(
		async ({ farmId, startAfter, startBefore }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/detectedTasks`, {
				params: { startAfter, startBefore }
			});
			return response?.data;
		},
		[apiRef]
	);

	const getDoneTask: useApiResult["getDoneTask"] = useCallback(
		async ({ farmId, taskId }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/doneTasks/${taskId}`);
			return response?.data;
		},
		[apiRef]
	);

	const deleteComingTask: useApiResult["deleteComingTask"] = useCallback(
		async ({ farmId, taskId }) => {
			await apiRef.delete(`userWorks/farms/${farmId}/comingTasks/${taskId}`);
		},
		[apiRef]
	);

	const updateDoneTask: useApiResult["updateDoneTask"] = useCallback(
		async ({ farmId, task, taskId }) => {
			await apiRef.patch(`userWorks/farms/${farmId}/doneTasks/${taskId}`, task);
		},
		[apiRef]
	);

	const markDetectedTasksAsRead: useApiResult["markDetectedTasksAsRead"] = useCallback(
		async ({ farmId, taskIds }) => {
			await apiRef.patch(`userWorks/farms/${farmId}/detectedTasks/markAsRead`, {
				taskIds
			});
		},
		[apiRef]
	);

	const markComingTaskAsDone: useApiResult["markComingTaskAsDone"] = useCallback(
		async ({ endTime, farmId, startTime, taskId }) => {
			const response = await apiRef.post(`userWorks/farms/${farmId}/comingTasks/${taskId}/done`, {
				endTime,
				startTime
			});
			return response?.data;
		},
		[apiRef]
	);

	const markDetectedTaskAsUsed: useApiResult["markDetectedTaskAsUsed"] = useCallback(
		async ({ detectedTaskId, farmId, task }) => {
			await apiRef.post(`userWorks/farms/${farmId}/detectedTasks/${detectedTaskId}/used`, task);
		},
		[apiRef]
	);

	const deleteDoneTask: useApiResult["deleteDoneTask"] = useCallback(
		async ({ farmId, taskId }) => {
			await apiRef.delete(`userWorks/farms/${farmId}/doneTasks/${taskId}`);
		},
		[apiRef]
	);

	const deleteDetectedTask: useApiResult["deleteDetectedTask"] = useCallback(
		async ({ farmId, taskId }) => {
			await apiRef.delete(`userWorks/farms/${farmId}/detectedTasks/${taskId}`);
		},
		[apiRef]
	);

	const exportTasks: useApiResult["exportTasks"] = useCallback(
		async ({ farmId, fieldIds, format, startAfter, startBefore, taskIds }) => {
			const response = await apiRef.get(`userWorks/farms/${farmId}/doneTasks.${format}`, {
				params: {
					fieldIds: fieldIds?.join(","),
					startAfter: startAfter.toISOString(),
					startBefore: startBefore.toISOString(),
					taskIds: taskIds?.join(",")
				},
				responseType: "blob"
			});
			return response?.data;
		},
		[apiRef]
	);

	const getSmagToken: useApiResult["getSmagToken"] = useCallback(async () => {
		const response = await apiRef.get("/partners/smag/loginUri");
		return response?.data?.uri;
	}, [apiRef]);

	const getSmagMobileToken: useApiResult["getSmagMobileToken"] = useCallback(async () => {
		const response = await apiRef.get("/partners/smag/loginMobileUri");
		return response?.data?.uri;
	}, [apiRef]);

	const linkToSmag: useApiResult["linkToSmag"] = useCallback(
		async ({ code }) => {
			await apiRef.post("/partners/smag/link", { code });
		},
		[apiRef]
	);

	const deleteSmagToken: useApiResult["deleteSmagToken"] = useCallback(async () => {
		await apiRef.delete("/partners/smag/token");
	}, [apiRef]);

	const getSmagFarms: useApiResult["getSmagFarms"] = useCallback(
		async (cropYear, farmNameFilter) => {
			const response = await apiRef.get("partners/smag/farms", { params: { cropYear, farmNameFilter } });
			return response?.data;
		},
		[apiRef]
	);

	const productAdministrationRequests: useApiResult["productAdministrationRequests"] = useCallback(
		async ({ callbackError, data, method, params, translationKey, url }, HttpError) => {
			try {
				const response = await apiRef.request({
					baseURL: `${apiRef.defaults.baseURL}/administration`,
					data,
					method,
					params,
					url
				});
				return response?.data;
			} catch (e) {
				const errorData = e?.response?.data;
				const errors = errorData?.errors;
				if (errors) {
					Object.keys(errors).forEach((k) => {
						errors[k] = `resources.${translationKey}.errors.${errors[k]}`;
					});
				}
				callbackError && callbackError(e);
				return Promise.reject(
					new HttpError(
						(errorData && errorData?.description) || e?.response?.statusText,
						e?.response?.status,
						{
							...errorData,
							errors
						}
					)
				);
			}
		},
		[apiRef]
	);

	const getSmagFields: useApiResult["getSmagFields"] = useCallback(
		async ({ cropYear, farmUid }) => {
			const response = await apiRef.get(`partners/smag/farms/${farmUid}/cropZones`, {
				params: { cropYear }
			});
			return response?.data;
		},
		[apiRef]
	);

	const uploadEdiFile: useApiResult["uploadEdiFile"] = useCallback(
		async (file) => {
			const response = await apiRef.post(
				`userFiles/telepacAgroEdi/uploadFile`,
				{
					file
				},
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);
			return response?.data?.id;
		},
		[apiRef]
	);

	const deleteNoticeFile: useApiResult["deleteNoticeFile"] = useCallback(
		async (noticeKey) => {
			await apiRef.delete(`userFiles/productNotices/${noticeKey}`);
		},
		[apiRef]
	);

	const uploadNoticeFile: useApiResult["uploadNoticeFile"] = useCallback(
		async ({ ammId, file, ucName }) => {
			const response = await apiRef.post(
				`userFiles/productNotices/uploadFile`,
				{
					ammId,
					file,
					ucName
				},
				{
					headers: {
						"Content-Type": "multipart/form-data"
					}
				}
			);
			return response?.data?.id;
		},
		[apiRef]
	);

	const getEdiFields: useApiResult["getEdiFields"] = useCallback(
		async ({ fileId, geofolia }) => {
			const response = await apiRef.get(`partners/telepacAgroEdi/cropZones`, {
				params: { fileId, geofolia }
			});
			return response?.data;
		},
		[apiRef]
	);

	const importEdiFields: useApiResult["importEdiFields"] = useCallback(
		async ({ cropSynchronize, farmId, fields, fileId, geofolia }) => {
			const response = await apiRef.post(`farms/${farmId}/agroEdiImport`, {
				cropSynchronize,
				fields,
				fileId,
				geofolia
			});
			return response?.data;
		},
		[apiRef]
	);

	const importSmagFields: useApiResult["importSmagFields"] = useCallback(
		async ({ cropSynchronize, cropYear, farmId, fields, smagFarmUid }) => {
			const res = await apiRef.post(`/farms/${farmId}/smagImport`, {
				cropSynchronize,
				cropYear,
				fields,
				smagFarmUid
			});
			return res.data;
		},
		[apiRef]
	);

	const exportSmagTasks: useApiResult["exportSmagTasks"] = useCallback(
		async ({ farmId, taskIds }) => {
			const response = await apiRef.post(`userWorks/farms/${farmId}/doneTasks/smagExports`, { taskIds });
			return response?.data;
		},
		[apiRef]
	);

	const deleteUser: useApiResult["deleteUser"] = useCallback(async () => {
		await apiRef.delete("/me");
	}, [apiRef]);

	const createMixture: useApiResult["createMixture"] = useCallback(
		async ({ farmId, mixture }) => {
			const response = await apiRef.post(`userWorks/farms/${farmId}/mixtures`, mixture);
			return response?.data;
		},
		[apiRef]
	);

	const getMixtures: useApiResult["getMixtures"] = useCallback(
		async ({ farmId, page }) => {
			const startAt = getStartOfDayAsJSDate(new Date());
			const endAt = substractHours(addDays(startAt, 5), 1);
			const response = await apiRef.get(`userWorks/farms/${farmId}/mixtures`, {
				params: { endAt, page, startAt },
				signal: controllerRef?.current?.signal
			});

			return response?.data;
		},
		[apiRef]
	);

	const getMixture: useApiResult["getMixture"] = useCallback(
		async ({ farmId, mixtureId }) => {
			const startAt = getStartOfDayAsJSDate(new Date());
			const endAt = substractHours(addDays(startAt, 5), 1);
			const response = await apiRef.get(`userWorks/farms/${farmId}/mixtures/${mixtureId}`, {
				params: { endAt, startAt }
			});
			return response?.data;
		},
		[apiRef]
	);

	const deleteMixture: useApiResult["deleteMixture"] = useCallback(
		async ({ farmId, mixtureId }) => {
			await apiRef.delete(`userWorks/farms/${farmId}/mixtures/${mixtureId}`);
		},
		[apiRef]
	);

	const updateMixture: useApiResult["updateMixture"] = useCallback(
		async ({ farmId, mixture, mixtureId, taskIds }) => {
			await apiRef.put(`userWorks/farms/${farmId}/mixtures/${mixtureId}`, mixture, {
				params: {
					taskIdsToModify: taskIds?.join(",") || null
				}
			});
		},
		[apiRef]
	);

	const getMileosVarieties: useApiResult["getMileosVarieties"] = useCallback(async () => {
		const response = await apiRef.get("/partners/mileos/cropVarieties");
		return response?.data;
	}, [apiRef]);

	const getMileosInfos: useApiResult["getMileosInfos"] = useCallback(
		async ({ farmId, fieldId }) => {
			const response = await apiRef.get(`/farms/${farmId}/fields/${fieldId}/mileos`);
			return response?.data;
		},
		[apiRef]
	);

	const getMileosRainFall: useApiResult["getMileosRainFall"] = useCallback(
		async ({ farmId, fieldId }) => {
			const response = await apiRef.get(`/farms/${farmId}/fields/${fieldId}/mileosRainFall`, {
				params: { endAt: formatDateToLocale(getStartOfDayAsJSDate(new Date()), "fr-CA") }
			});
			return response?.data;
		},
		[apiRef]
	);

	const patchMileosRainFall: useApiResult["patchMileosRainFall"] = useCallback(
		async ({ farmId, fieldId, rainFall }) => {
			await apiRef.patch(`/farms/${farmId}/fields/${fieldId}/mileosRainFall`, rainFall);
		},
		[apiRef]
	);

	const patchMileosField: useApiResult["patchMileosField"] = useCallback(
		async ({ farmId, fieldId, mileosInfos }) => {
			const formattedMileosInfos = {
				...mileosInfos,
				observations: mileosInfos?.observations?.map((o) => formatDateToLocale(new Date(o), "fr-CA")),
				phases: mileosInfos?.phases?.map((p) => ({
					...p,
					date: p?.date ? formatDateToLocale(new Date(p?.date), "fr-CA") : null
				}))
			};
			await apiRef.patch(`/farms/${farmId}/fields/${fieldId}/mileos`, formattedMileosInfos);
		},
		[apiRef]
	);

	return {
		cancel,
		checkEmailAndSendPasswordReset,
		checkSetup,
		checkTank,
		checkToken,
		createArvalisDevice,
		createComingTask,
		createDoneTask,
		createFarm,
		createField,
		createIrrigation,
		createMixture,
		createNozzle,
		createPushToken,
		createSprayer,
		createUser,
		deleteArvalisDevice,
		deleteComingTask,
		deleteDetectedTask,
		deleteDoneTask,
		deleteFarm,
		deleteFarmMileosAdvisor,
		deleteFields,
		deleteIrrigation,
		deleteMixture,
		deleteNoticeFile,
		deleteNotification,
		deleteNozzle,
		deletePushToken,
		deleteSmagToken,
		deleteSprayer,
		deleteUser,
		exportMileosCampaign,
		exportSmagTasks,
		exportTasks,
		getAbout,
		getAdminToken,
		getArvalisDevices,
		getArvalisDevicesConstructors,
		getChargebeePortalSession,
		getComingTask,
		getComingTasks,
		getCoops,
		getCoopUsers,
		getCrops,
		getDetectedTasks,
		getDoneTask,
		getDoneTasks,
		getEdiFields,
		getFarmMileosAdvisors,
		getFarms,
		getFarmWeather,
		getFields,
		getIrrigation,
		getIrrigations,
		getLocaleWeather,
		getMileosAdvisors,
		getMileosIndicesByFarm,
		getMileosIndicesByField,
		getMileosInfos,
		getMileosRainFall,
		getMileosVarieties,
		getMixture,
		getMixtures,
		getModulation,
		getNetatmoStationsAround,
		getNotification,
		getNotifications,
		getNozzles,
		getPlans,
		getProductConstants,
		getProductDosesUsages,
		getProducts,
		getRadar,
		getRealtimePosition,
		getRealtimeWeather,
		getRPGFields,
		getSmagFarms,
		getSmagFields,
		getSmagMobileToken,
		getSmagToken,
		getSprayers,
		getTargets,
		getUser,
		importEdiFields,
		importSmagFields,
		linkToSmag,
		markComingTaskAsDone,
		markDetectedTaskAsUsed,
		markDetectedTasksAsRead,
		patchFarmMileosAdvisors,
		patchFields,
		patchMileosField,
		patchMileosRainFall,
		patchSprayerDefaultNozzleId,
		patchUser,
		productAdministrationRequests,
		resetPassword,
		sendFakeMileosTreatment,
		sendProductMissingTicket,
		updateComingTask,
		updateDoneTask,
		updateFarm,
		updateIrrigation,
		updateMaterializedView,
		updateMixture,
		updateNotification,
		updateNozzle,
		updateSprayer,
		uploadEdiFile,
		uploadNoticeFile
	};
};
